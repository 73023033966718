import { Green, Divider, Bold } from '../../../../../components/CommonBox'
// import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { ManualnePridat } from '../../../../../components/icons/KubikIcons'
import { NexusGenFieldTypes } from 'kubik-server'
import { useTranslation, Trans } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import get from 'lodash/get'
import IconButton from '@material-ui/core/IconButton'
import IconPaper from '../../../../../components/IconPaper'
import InsideLayout from '../../../../../components/layouts/InsideLayout'
import IntroLayout from '../../../../../components/layouts/IntroLayout'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import moment from 'moment'
import React, {ReactNode, useState} from 'react'
import ScrollToTop from '../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useRouter from '../../../../../hooks/useRouter'
import useUser from '../../../../../hooks/useUser'
import {Link} from "react-router-dom";
import {SupportLayout} from "../index";
import Podpora from "../../../../../components/icons/Podpora";

const useStyles = makeStyles((theme) => ({
}))



const SupportWizard: React.FC = (props) => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()


  return (

    <SupportLayout title={"Úpravy v průvodci"}>

      <h4>Možnost odeslat formulář jménem uživatele</h4>
      <p>Odeslání formuláře tak, jako kdyby to udělal sám uživatel. Používat s povolením a rozvahou - v ideálním případě by si formulář měl vždy posílat sám uživatel.</p>
      <ul>
        <li>přejdi do <Link to={`/${user.data.id}/employees`}><Green>Zaměstnanců</Green></Link></li>
        <li>vyber zaměstnance, ve vyskakovacím okně klikni na <Green>Průvodce daňových slev</Green></li>
        <li>na konci v přehledu můžeš formulář odeslat</li>
        <li>při procházení tímto způsobem můžeš <i>kdykoliv</i> měnit i Roční zúčtování</li>
      </ul>

      <h4>Odstranění omezení datumem</h4>
      <p>Zaměstnanec je při výběru omezený datumem nástupu a dalšími pravidly, mimo jiné tím, že v měsíčních slevách nelze vybírat měsíce zpětně (dále jen <i>rozhodný datum</i>). Takto to lze obejít.</p>
      <ul>
        <li>Při procházení průvodce za uživatele (viz výše) vidíš menu s ikonkou <Podpora color={'primary'} /></li>
        <li>V něm je možnost ovlivnit omezení <i>rozhodným datem</i></li>
        <li>Tuto možnost lze měnit, při zapnutí:
          <ul>
            <li>uživatel i ty můžete nastavit jakýkoli měsíc u slev (vyjma jiných, pevných pravidel, např. podle věku dítěte)</li>
            <li>takto nastavený formulář lze jednou bez omezení odeslat - můžeš to udělat ty i sám zaměstnanec</li>
            <li>po odeslání se pravidla opět zapnou</li>
          </ul>
        </li>

      </ul>


    </SupportLayout>
  )
}

export default SupportWizard
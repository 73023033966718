import { Redirect, Switch, Route } from 'react-router-dom'
import { AnnualWizardStep } from '../../../../generated/globalTypes'
import { useUser } from '../../../../hooks'
import BasicRebate from './pages/BasicRebate'
import DisabilityRebate from './pages/DisabilityRebate'
import ExamRebate from './pages/ExamRebate'
import GiftRebate from './pages/GiftRebate'
import ChildrenRebate from './pages/ChildrenRebate'
import Intro from './pages/Intro'
import ResponsiveIntro from './pages/ResponsiveIntro'
import LifeInsuranceRebate from './pages/LifeInsuranceRebate'
import LoanRebate from './pages/LoanRebate'
import PensionInsuranceRebate from './pages/PensionInsuranceRebate'
import PreschoolRebate from './pages/PreschoolRebate'
import PreviousEmployers from './pages/PreviousEmployers'
import Questionnaire from './pages/Questionnaire'
import TaxResidence from './pages/TaxResidence'
import React from 'react'
import Root from './pages/Root'
import Skip from './pages/Skip'
import SpouseRebate from './pages/SpouseRebate'
import StudentRebate from './pages/StudentRebate'
import Summary from './pages/Summary'
import UnionRebate from './pages/UnionRebate'
import ZtppRebate from './pages/ZtppRebate'
import ForeclosureRebate from "./pages/ForeclosureRebate";
import MonthlyRebatesRoot from "./pages/MonthlyRebatesRoot"
import InvestmentRebate from "./pages/InvestmentRebate";
import LongTermCareRebate from "./pages/LongTermCareRebate";

const Rebates = () => {
  const { user } = useUser()
  const annualRebate = user.data.annualRebate

  if (
    annualRebate &&
    annualRebate.settlementRequest &&
    annualRebate.settlementRequest.status === 'NEW'
  ) {
    return <Redirect to={`/${user.data.id}/overview`} />
  }

  const redirectTo = () => {
    const {
      data: { annualRebate },
    } = user

    const redirectUrl = '/:userId/annual-rebates'

    if (!annualRebate) {
      return redirectUrl + '/intro'
    } else {
      switch (annualRebate.wizardStep) {
        case AnnualWizardStep.PREV_EMPLOYER:
          return redirectUrl + '/previousEmployers'
        case AnnualWizardStep.REBATE:
          return redirectUrl + '/root'
        case AnnualWizardStep.REBATE_MONTHLY:
          return redirectUrl + '/rootMonthly'
        case AnnualWizardStep.SUMMARY:
          return redirectUrl + '/summary'
        case AnnualWizardStep.TAX_RESIDENCE:
          return redirectUrl + '/taxResidence'
        case AnnualWizardStep.QUESTIONNAIRE:
        default:
          return redirectUrl + '/questionnaire'
      }
    }
    // const status = rebateStatus().annualRebate.status
    // } else if (status !== 'NEW') {
    //   console.log('__redirect', annualRebate.wizardStep)
    //   // Už ve slevách něco má
    //   redirect += 'root'
    // } else {
    //   redirect += 'questionnaire'
    // }
    // return redirect
  }

  return (
    <Switch>
      <Route path="/:userId/annual-rebates/skip" component={Skip} />
      <Route
        path="/:userId/annual-rebates/basic-rebate"
        component={BasicRebate}
      />
      <Route
        path="/:userId/annual-rebates/student-rebate"
        component={StudentRebate}
      />
      <Route
        path="/:userId/annual-rebates/disability-rebate"
        component={DisabilityRebate}
      />
      <Route
        path="/:userId/annual-rebates/children-rebate"
        component={ChildrenRebate}
      />
      <Route
        path="/:userId/annual-rebates/ztpp-rebate"
        component={ZtppRebate}
      />
      <Route
        path="/:userId/annual-rebates/life-insurance-rebate"
        component={LifeInsuranceRebate}
      />
      <Route
        path="/:userId/annual-rebates/pension-insurance-rebate"
        component={PensionInsuranceRebate}
      />
      <Route
        path="/:userId/annual-rebates/union-rebate"
        component={UnionRebate}
      />
      <Route
        path="/:userId/annual-rebates/foreclosure-rebate"
        component={ForeclosureRebate}
      />
      <Route
        path="/:userId/annual-rebates/investment-rebate"
        component={InvestmentRebate}
      />
      <Route
        path="/:userId/annual-rebates/long-term-care-rebate"
        component={LongTermCareRebate}
      />
      <Route
        path="/:userId/annual-rebates/exam-rebate"
        component={ExamRebate}
      />
      <Route
        path="/:userId/annual-rebates/gift-rebate"
        component={GiftRebate}
      />
      <Route
        path="/:userId/annual-rebates/spouse-rebate"
        component={SpouseRebate}
      />
      <Route
        path="/:userId/annual-rebates/preschool-rebate"
        component={PreschoolRebate}
      />
      <Route
        path="/:userId/annual-rebates/loan-rebate"
        component={LoanRebate}
      />
      <Route path="/:userId/annual-rebates/summary" component={Summary} />
      <Route path="/:userId/annual-rebates/root" component={Root} />
      <Route path="/:userId/annual-rebates/rootMonthly" component={MonthlyRebatesRoot} />
      <Route
        path="/:userId/annual-rebates/previousEmployers"
        component={PreviousEmployers}
      />
      <Route
        path="/:userId/annual-rebates/taxResidence"
        component={TaxResidence}
      />
      <Route
        path="/:userId/annual-rebates/questionnaire"
        component={Questionnaire}
      />
      <Route path="/:userId/annual-rebates/intro" component={Intro} />
      <Route
        path="/:userId/annual-rebates/responsive"
        component={ResponsiveIntro}
      />
      {/* <Route path="/:userId/annual-rebates" component={Root} /> */}

      {/* <Redirect to="/:userId/annual-rebates/questionnaire" /> */}
      <Redirect to={redirectTo()} />
    </Switch>
  )
}

export default Rebates

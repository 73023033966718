import { Upravit } from '../../../../../components/icons/KubikIcons'
import { Divider, SpaceBetween } from '../../../../../components/CommonBox'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles({
  divider: {},
  container: {
    "&:last-of-type": {
      "& $divider": {
        display: "none"
      }
    }
  }
})

const SummaryRebate: React.FC<{
  label: string
  icon: React.ReactElement
  editLinkTo: string
}> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const classes = useStyles()

  const { children, label, icon, editLinkTo } = props

  return (
    <Box width="100%" position="relative" maxWidth="100%" className={classes.container}>
      <Box
        position={compactScreen ? undefined : 'absolute'}
        left={-60}
        top={-15}
        fontSize="2.5rem"
      >
        {icon &&
          React.cloneElement(icon, {
            fontSize: 'inherit',
          })}
      </Box>
      <SpaceBetween mb={2}>
        <Typography variant="h2" color="primary">
          {label}
        </Typography>
        <Typography>
          <Button color="primary" component={Link} to={editLinkTo}>
            {t('common.edit')}
            <Box ml={1} display="flex" component="span">
              <Upravit color="primary" fontSize="small" />
            </Box>
          </Button>
        </Typography>
      </SpaceBetween>

      {children}

      <Divider my={6} className={classes.divider} />
    </Box>
  )
}

export default SummaryRebate

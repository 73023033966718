import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Green, Divider } from '../../../../../../components/CommonBox'
import { RebateConditions, RebateExpandable } from '../../../../../../shared/Rebates/Conditions'

export const PensionInsuranceRebateConditions: React.FC = () => {
    const { t } = useTranslation()
  
    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
            <Trans i18nKey={'annualPensionInsuranceRebate.conditionsDetail01'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>, který má sjednané <strong>penzijní připojištění splňující několik podmínek</strong>.
            </Trans>,
            <Trans i18nKey={'annualPensionInsuranceRebate.conditionsDetail02'}>
                Slevu si můžete uplatnit, pokud Vaše <strong>příspěvky za rok přesáhly 12 000 Kč</strong> (více než 1 000 Kč měsíčně).
            </Trans>,
            <Trans i18nKey={'annualPensionInsuranceRebate.conditionsDetail03'}>
            </Trans>,
            <Trans i18nKey={'annualPensionInsuranceRebate.conditionsDetail04'}>
                Většina potvrzení, které obdržíte od pojišťovny, <strong>již uvádí částku poníženou o 12 000 Kč</strong>, tudíž není potřeba již nic dalšího snižovat.
            </Trans>,
            <Trans i18nKey={'annualPensionInsuranceRebate.conditionsDetail05'}>
                Uplatnit si můžete pouze částku, kterou jste <strong>sami zaplatili na pojistném</strong>.
            </Trans>,
            <Trans i18nKey={'annualPensionInsuranceRebate.conditionsDetail06'}>
            </Trans>,
            <Trans i18nKey={'annualPensionInsuranceRebate.conditionsDetail07'}>
            </Trans>
        ]} />

        <Green>{t("common.docsInfo")}</Green>

        <RebateConditions items={[
            {
                primaryText: (
                    <Trans i18nKey="annualPensionInsuranceRebate.docsInfo01">
                        Potvrzení o výši zaplaceného pojistného
                    </Trans>
                ),
                supportText: t("annualPensionInsuranceRebate.docsInfo01b")
            },
            {
                primaryText: (
                    <Trans i18nKey="annualPensionInsuranceRebate.docsInfo02">
                        Kopie smlouvy penzijního připojištění
                    </Trans>
                ),
                supportText: <Trans i18nKey="annualPensionInsuranceRebate.docsInfo02b" />
            }
        ]} />
      </RebateExpandable>
    )
}


import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import { Green, Divider } from '../../../../../../components/CommonBox'
import { RebateConditions, RebateExpandable } from '../../../../../../shared/Rebates/Conditions'

export const LifeInsuranceRebateConditions: React.FC = () => {
    const { t } = useTranslation()

    return (
      <RebateExpandable>
        <Divider maxWidth="100%" width={680} my={4} />

        <Green>{t("common.mainInfo")}</Green>

        <RebateConditions items={[
            <Trans i18nKey={'annualLifeInsuranceRebate.conditionsDetail01'}>
                Slevu si může uplatnit <strong>každý zaměstnanec</strong>, který má sjednané <strong>soukromé životní pojištění splňující několik podmínek</strong>.
            </Trans>,
            <Trans i18nKey={'annualLifeInsuranceRebate.conditionsDetail02'}>
                Slevu si můžete uplatnit, pokud je <strong>pojištění sjednané alespoň na 5 let</strong>.
            </Trans>,
            <Trans i18nKey={'annualLifeInsuranceRebate.conditionsDetail03'}>
                Slevu si můžete uplatnit, pokud si <strong>pojištění vyberete nejdříve ve věku 60 let</strong>.
            </Trans>,
            <Trans i18nKey={'annualLifeInsuranceRebate.conditionsDetail04'}>
                Slevu si můžete uplatnit, pokud <strong>smlouva nemá možnost mimořádných výběrů</strong>.
            </Trans>,
            <Trans i18nKey={'annualLifeInsuranceRebate.conditionsDetail05'}>
                Slevu si můžete uplatnit, pokud jste <strong>uzavřeli pojištění sami sobě</strong> (jste pojistník i pojištěná osoba).
            </Trans>,
            <Trans i18nKey={'annualLifeInsuranceRebate.conditionsDetail06'}>
                Uplatnit si můžete částku, kterou jste <strong>sami zaplatili na pojistném</strong> (max. 24 000 Kč.)
            </Trans>,
            <Trans i18nKey={'annualLifeInsuranceRebate.conditionsDetail07'}>
                Uplatnit si můžete částku, kterou jste <strong>sami zaplatili na pojistném</strong> (max. 24 000 Kč.)
            </Trans>,
            <Trans i18nKey={'annualLifeInsuranceRebate.conditionsDetail08'}>
                Uplatnit si můžete částku, kterou jste <strong>sami zaplatili na pojistném</strong> (max. 24 000 Kč.)
            </Trans>
        ]} />

        <Green>{t("common.docsInfo")}</Green>

        <RebateConditions items={[
            {
                primaryText: (
                    <Trans i18nKey="annualLifeInsuranceRebate.docsInfo01">
                        Potvrzení od pojišťovny
                    </Trans>
                ),
                supportText: t('annualLifeInsuranceRebate.docsInfo01b')
            },
            {
                primaryText: (
                    <Trans i18nKey="annualLifeInsuranceRebate.docsInfo02">
                        Potvrzení od pojišťovny
                    </Trans>
                ),
                supportText: <Trans i18nKey='annualLifeInsuranceRebate.docsInfo02b' />
            }
        ]} />
      </RebateExpandable>
    )
}

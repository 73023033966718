import { Bold, Divider } from '../../../../../../components/CommonBox'
import { ClosePeriod, Normal, Remove } from './Type'
import { Invalida } from '../../../../../../components/icons/KubikIcons'
import { NexusGenFieldTypes } from 'kubik-server'
import { TRebateType } from '../gql'
import { useDisabilityLevelEnum, useRebateType } from '../../../../../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import CommentOfDenied from './CommentOfDenied'
import FileLink from '../../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React from 'react'
import StatusSwitcher, { StatusView, StatusViewText } from './StatusSwitcher'
import Typography from '@material-ui/core/Typography'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const DisabilityView: React.FC<{
  data: NexusGenFieldTypes['DisabilityRebate']
  hideComment?: boolean
  rebateType: TRebateType
}> = (props) => {
  const { t } = useTranslation()
  const { translate: translateDisabilityLevel } = useDisabilityLevelEnum()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  const { data, hideComment, rebateType } = props

  const showPeriods =
    rebateType === 'ANNUAL' ||
    rebateType === 'ANNUAL_CHANGE' ||
    rebateType === 'MONTHLY_HISTORY' ||
    rebateType === 'MONTHLY_HISTORY_CHANGE'

  return (
    <Grid container spacing={1}>
      <ClosePeriod data={data}>
        {t(
          `${
            rebateType === 'MONTHLY' ? 'monthly' : 'annual'
          }DisabilityRebate.closePeriodLabel_ACCOUNTANT`,
        )}
      </ClosePeriod>

      <Remove data={data} />

      <Normal data={data}>
        {!showPeriods && data.disabilityLevel && (
          <>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Bold>{t('common.disabilityLevel')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Typography>
                {translateDisabilityLevel(data.disabilityLevel)}
              </Typography>
            </Grid>
          </>
        )}

        {!showPeriods &&
          (data.confirmationValidityFrom || data.confirmationValidityTo) && (
            <>
              <Grid item xs={compactScreen ? 12 : 5}>
                <Bold>{t('common.validityPeriodLabel')}</Bold>
              </Grid>
              <Grid item xs={compactScreen ? 12 : 7}>
                <Typography>
                  {data.confirmationValidityFrom &&
                    moment(data.confirmationValidityFrom).format('MMMM YYYY')}
                  {' - '}
                  {data.confirmationValidityTo &&
                    moment(data.confirmationValidityTo).format('MMMM YYYY')}
                </Typography>
              </Grid>
            </>
          )}

        {(showPeriods || rebateType === 'MONTHLY_PREV_YEAR') &&
          (data.prevYearPeriods || []).map((period) => (
            <React.Fragment key={period.id}>
              <Grid item xs={compactScreen ? 12 : 5}>
                <Bold>{t('common.disabilityLevel')}</Bold>
              </Grid>
              <Grid item xs={compactScreen ? 12 : 7}>
                <Typography>
                  {period.disabilityLevel &&
                    translateDisabilityLevel(period.disabilityLevel)}
                </Typography>
              </Grid>
              <Grid item xs={compactScreen ? 12 : 5}>
                <Bold>{t('common.validityPeriodLabel')}</Bold>
              </Grid>
              <Grid item xs={compactScreen ? 12 : 7}>
                <Typography>
                  {period.from && moment(period.from).format('MMMM YYYY')}
                  {' - '}
                  {period.to && moment(period.to).format('MMMM YYYY')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box mb={1} />
              </Grid>
            </React.Fragment>
          ))}

        <Grid item xs={12}>
          <Bold mb={1} display="block">
            {t('common.uploadedConfirmations')}
          </Bold>
          <Box pl={compactScreen ? 0 : 4}>
            {data.disabilityConfirmationFiles &&
              data.disabilityConfirmationFiles.map((file) => (
                <FileLink key={file.id} file={file} />
              ))}
          </Box>

          <Box pl={compactScreen ? 0 : 4}>
            {data.disabilityOtherFiles &&
              data.disabilityOtherFiles.map((rel) => (
                <FileLink key={rel.id} file={rel.file} />
              ))}
          </Box>
        </Grid>
      </Normal>

      {!hideComment && (
        <Grid item xs={12}>
          <CommentOfDenied rebateName="disabilityRebate" />
        </Grid>
      )}
    </Grid>
  )
}

interface IProps {
  data: NexusGenFieldTypes['DisabilityRebate']
  previous: NexusGenFieldTypes['DisabilityRebate'][]
  rebateType: TRebateType
  bottomDivider?: boolean
}

export const DisabilityRebatePrevious: React.FC<Omit<IProps, 'data'>> = (
  props,
) => {
  const { previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const [first] = previous

  if (!first) return null

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Invalida fontSize="inherit" />
          </Box>
          <StatusView status={first.status}>
            {t('rebate.disabilityRebate')}
          </StatusView>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Invalida fontSize="inherit" />
            </Box>
            <StatusView status={first.status} compactView />
          </Box>
          <Box>
            <StatusViewText status={first.status}>
              {t('rebate.disabilityRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}
      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <DisabilityView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

const DisabilityRebate: React.FC<IProps> = (props) => {
  const { data, previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  const isAnnual = rebateType === 'ANNUAL' || rebateType === 'ANNUAL_CHANGE' || rebateType === 'ANNUAL_POZP'

  const { translate } = useRebateType(isAnnual, false)
  const type = translate(data.type)
  const title = `${t('rebate.disabilityRebate')} ${type ? `(${type})` : ''}`

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Invalida fontSize="inherit" />
          </Box>
          <StatusSwitcher rebateName="disabilityRebate">
            {title}
          </StatusSwitcher>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Invalida fontSize="inherit" />
            </Box>
            <StatusSwitcher rebateName="disabilityRebate" compactView>
              {title}
            </StatusSwitcher>
          </Box>
          <Box>
            <StatusViewText status={'APPLY'}>
              {title}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <DisabilityView data={data} rebateType={rebateType} />

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate
            key={data.id}
            status={data.status}
            settlementRequest={data.settlementRequest}
            commentOfDenied={data.commentOfDenied}
          >
            <DisabilityView data={data} rebateType={rebateType} hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>

      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

export default DisabilityRebate

import { Bold, Divider } from '../../../../../../components/CommonBox'
import { NexusGenEnums, NexusGenFieldTypes } from 'kubik-server'
import { TSettlementRequest } from '../gql'
import { useTranslation } from 'react-i18next'
import BasicRebate from './BasicRebate'
import DisabilityRebate from './DisabilityRebate'
import ExamRebate from './ExamRebate'
import GiftRebate from './GiftRebate'
import ChildrenRebate from './ChildrenRebate'
import LifeInsuranceRebate from './LifeInsuranceRebate'
import LoanRebate from './LoanRebate'
import PensionInsuranceRebate from './PensionInsuranceRebate'
import PreschoolRebate from './PreschoolRebate'
import React from 'react'
import SpouseRebate from './SpouseRebate'
import StudentRebate from './StudentRebate'
import UnionRebate from './UnionRebate'
import ForeclosureRebate from './ForeclosureRebate'
import ZtppRebate from './ZtppRebate'
import { MonthlyTaxQuestionnaireForm } from './MonthlyTaxQuestionnaire'
import moment from 'moment'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import InvestmentRebate from "./InvestmentRebate";
import LongTermCareRebate from "./LongTermCareRebate";

const allowedStatus = (
  rebate?: { status: NexusGenEnums['RebateStatus'] } | null,
) => rebate && ['APPLY', 'CONFIRMED', 'DENIED'].includes(rebate.status)

const allowedChildrenStatus = (rebate?: NexusGenFieldTypes['ChildrenRebate']) =>
  rebate &&
  (['APPLY', 'CONFIRMED', 'DENIED'].includes(rebate.otherParentStatus) ||
    (rebate.children &&
      rebate.children.some((ch) =>
        ['APPLY', 'CONFIRMED', 'DENIED'].includes(ch.status),
      )))

const createdBefore = ({ createdAt }: { createdAt: any }) => (rebate: {
  createdAt: any
}) => moment(rebate.createdAt).isBefore(createdAt)

// const allowedStatuses = (
//   ...rebates: ({ status: NexusGenEnums['RebateStatus'] } | undefined)[]
// ) => {
//   return rebates.map((rebate) => allowedStatus(rebate)).includes(true)
// }

interface IProps {
  data: TSettlementRequest
}

const Rebates: React.FC<IProps> = (props) => {
  const { data } = props
  const { t } = useTranslation()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)

  const {
    basicRebate,
    studentRebate,
    childrenRebate,
    disabilityRebate,
    ztppRebate,
    spouseRebate,
    giftRebate,
    preschoolRebate,
    lifeInsuranceRebate,
    pensionInsuranceRebate,
    loanRebate,
    unionRebate,
    foreclosureRebate,
    investmentRebate,
    longTermCareRebate,
    examRebate,
    annualRebate,
    monthlyRebate,
    monthlyTaxQuestionnaire,
    type: rebateType,
  } = data

  const {
    basicRebates = [],
    studentRebates = [],
    childrenRebates = [],
    disabilityRebates = [],
    ztppRebates = [],
    spouseRebates = [],
    giftRebates = [],
    preschoolRebates = [],
    lifeInsuranceRebates = [],
    pensionInsuranceRebates = [],
    loanRebates = [],
    unionRebates = [],
    foreclosureRebates = [],
    investmentRebates = [],
    longTermCareRebates = [],
    examRebates = [],
  } = {
    ...annualRebate,
    ...monthlyRebate,
  }

  // TODO zrušit to Petrovo harakiri na v resolveru annualRebate a místo toho ty slevy odfiltrovat zde.
  // potřebuju totiž všechny (i ty v "budoucnosti") kvuli Schváleným/Zamítnutým slevám

  const rebatesComponents: JSX.Element[] = []

  if (allowedStatus(spouseRebate)) {
    rebatesComponents.push(
      <SpouseRebate
        data={spouseRebate}
        previous={spouseRebates.filter(createdBefore(spouseRebate))}
        rebateType={rebateType}
      />,
    )
  }
  if (allowedStatus(giftRebate)) {
    rebatesComponents.push(
      <GiftRebate
        data={giftRebate}
        previous={giftRebates.filter(createdBefore(giftRebate))}
        rebateType={rebateType}
      />,
    )
  }
  if (allowedStatus(preschoolRebate)) {
    rebatesComponents.push(
      <PreschoolRebate
        data={preschoolRebate}
        previous={preschoolRebates.filter(createdBefore(preschoolRebate))}
        rebateType={rebateType}
      />,
    )
  }
  if (allowedStatus(lifeInsuranceRebate)) {
    rebatesComponents.push(
      <LifeInsuranceRebate
        data={lifeInsuranceRebate}
        previous={lifeInsuranceRebates.filter(
          createdBefore(lifeInsuranceRebate),
        )}
        rebateType={rebateType}
      />,
    )
  }
  if (allowedStatus(pensionInsuranceRebate)) {
    rebatesComponents.push(
      <PensionInsuranceRebate
        data={pensionInsuranceRebate}
        previous={pensionInsuranceRebates.filter(
          createdBefore(pensionInsuranceRebate),
        )}
        rebateType={rebateType}
      />,
    )
  }
  if (allowedStatus(loanRebate)) {
    rebatesComponents.push(
      <LoanRebate
        data={loanRebate}
        previous={loanRebates.filter(createdBefore(loanRebate))}
        rebateType={rebateType}
      />,
    )
  }
  if (allowedStatus(unionRebate)) {
    rebatesComponents.push(
        <UnionRebate
            data={unionRebate}
            previous={unionRebates.filter(createdBefore(unionRebate))}
            rebateType={rebateType}
        />,
    )
  }
  if (allowedStatus(foreclosureRebate)) {
    rebatesComponents.push(
        <ForeclosureRebate
            data={foreclosureRebate}
            previous={foreclosureRebates.filter(createdBefore(foreclosureRebate))}
            rebateType={rebateType}
        />,
    )
  }

  if (allowedStatus(investmentRebate)) {
    rebatesComponents.push(
      <InvestmentRebate
        data={investmentRebate}
        previous={investmentRebates.filter(createdBefore(investmentRebate))}
        rebateType={rebateType}
      />,
    )
  }

  if (allowedStatus(longTermCareRebate)) {
    rebatesComponents.push(
      <LongTermCareRebate
        data={longTermCareRebate}
        previous={longTermCareRebates.filter(createdBefore(longTermCareRebate))}
        rebateType={rebateType}
      />,
    )
  }
  if (allowedStatus(examRebate)) {
    rebatesComponents.push(
      <ExamRebate
        data={examRebate}
        previous={examRebates.filter(createdBefore(examRebate))}
        rebateType={rebateType}
      />,
    )
  }

  if (allowedStatus(basicRebate)) {
    rebatesComponents.push(
      <BasicRebate
        data={basicRebate}
        previous={basicRebates.filter(createdBefore(basicRebate))}
        rebateType={rebateType}
      />,
    )
  }

  if (allowedStatus(studentRebate)) {
    rebatesComponents.push(
      <StudentRebate
        data={studentRebate}
        previous={studentRebates.filter(createdBefore(studentRebate))}
        rebateType={rebateType}
      />,
    )
  }

  if (allowedChildrenStatus(childrenRebate)) {
    rebatesComponents.push(
      <ChildrenRebate
        data={childrenRebate}
        previous={childrenRebates.filter(createdBefore(childrenRebate))}
        rebateType={rebateType}
      />,
    )
  }

  if (allowedStatus(disabilityRebate)) {
    rebatesComponents.push(
      <DisabilityRebate
        data={disabilityRebate}
        previous={disabilityRebates.filter(createdBefore(disabilityRebate))}
        rebateType={rebateType}
      />,
    )
  }
  if (allowedStatus(ztppRebate)) {
    rebatesComponents.push(
      <ZtppRebate
        data={ztppRebate}
        previous={ztppRebates.filter(createdBefore(ztppRebate))}
        rebateType={rebateType}
      />,
    )
  }

  return (
    <>
      {rebatesComponents.map((c, idx, arr) => (
        <React.Fragment key={'rebatesComponents' + idx}>
          {c}
          {idx < arr.length - 1 && <Divider my={6} />}
        </React.Fragment>
      ))}

      {rebatesComponents.length === 0 && (
        <Bold
          width={800}
          display="block"
          textAlign={compactScreen ? undefined : 'center'}
          mb={2}
        >
          {t(data.type === 'ANNUAL_CHANGE' ? 'settlementRequest.noAdditionalApplyingRebates' : 'settlementRequest.noApplyingRebates')}
        </Bold>
      )}
    </>
  )
}

export default Rebates

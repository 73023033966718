
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import moment from 'moment'
import React, {useState} from 'react'
import useUser from '../../../../../hooks/useUser'
import {SupportLayout} from "../index";
import useForm from "../../../../../hooks/useForm";
import {useLazyQuery} from "react-apollo";
import {Observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";
import MonthYearPicker from "../../../../../components/form/MonthYearPicker";
import {GET_REBATE_ENDINGS, TRebateEnding} from "./gql";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
}))



const SupportEndingRebates: React.FC = (props) => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()

  const [forMonth, setForMonth] = useState(moment())
  const [loadRebateEndings, result] = useLazyQuery<{rebateEndings: TRebateEnding[]}>(GET_REBATE_ENDINGS, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
    },
  })

  const { bind, form } = useForm(
    {
      forMonth: forMonth
    },
    {
      forMonth: {
        label: 'Pro měsíc',
        rule: 'required|date',
      },

    },
    {
      submitWholeModel: true,
      async onSubmit(formData: any, form) {
        setForMonth(moment(formData.forMonth))
        loadRebateEndings({
          variables: {
            month: moment(formData.forMonth).format('YYYY-MM-DD')
          }
        })
      },
    },
  )

  const data = (result.data && result.data.rebateEndings) || []

  return (

    <SupportLayout title={"Končící slevy"}>

      <h4>Zobrazit končící slevy</h4>
      <p>Podle stejného vyhodnocení budou odeslány emaily o končících slevách. (podle aktivity uživatele se ale ještě můžou změnit)</p>
      <p>Zadaný měsíc je ten, ve kterém by se posílaly emaily (tj. posílají se např. měsíc před koncem slevy etc.)</p>

      <Grid container>
        <Grid xs={12} md={4} item>
        <Observer>
          {() => (
            <>
              <MonthYearPicker
                {...bind('forMonth')}
                placeholder={'Vyberte měsíc'}
                variant="inline"
                autoOk
                openTo="month"
                views={['year', 'month']}
                inputVariant="outlined"
              /><br/><br/>
              <Button
                variant="contained"
                color="primary"
                onClick={() => form.submit()}
              >
                {t('common.submit')}
              </Button>
            </>
          )}
        </Observer>
      </Grid>

        <Grid xs={12} md={8} item>
          {!!result && !!result.loading && <CircularProgress/>}
          {!!result.called && !!data && !result.loading && <>
            <h2>V měsíci {forMonth.format('MMMM YYYY')} se pošlou tyto emaily o konci slevy</h2>
            {data.length} uživatelů
            {data.map(ending => {
              return (<div style={{padding: '0.5rem 0', borderBottom: '1px solid #eee'}}>
                {!!ending.user &&
                  <h4>{ending.user.firstname} {ending.user.lastname} {ending.user.personalNumber && <span>({ending.user.personalNumber})</span>}</h4>}
                {!!ending.user && <i>{ending.user.email}</i>}
                {!!ending.ztpp && !!ending.ztpp.length && <div>ZTPP: ANO - konec {moment(ending.ztpp).format('DD. MM. YYYY')}</div>}
                {!!ending.disability && !!ending.disability.length && <div>Invalidita: ANO - konec {moment(ending.disability).format('DD. MM. YYYY')}</div>}
                {(ending.children || []).map(child => <>
                  <div>Dítě: {child.name}</div>
                  <div>
                    {child.type === 'EXPIRED' && <span>Konec studenta</span>}
                    {child.type === 'ADULTHOOD' && <span>Dospělost dítěte</span>}
                    {child.type === 'ENDED' && <span>Doběhnutí slevy</span>}
                  </div>
                  <div>Konec v: {moment(child.endingAt).format('DD. MM. YYYY')}</div>
                </>)}
              </div>)
            })}
          </>}

        </Grid></Grid>
    </SupportLayout>
  )
}

export default SupportEndingRebates
import {
  IUpdateOwnAnnualLifeInsuranceRebateData,
  TUpdateOwnAnnualLifeInsuranceRebateVariables,
  OWN_ANNUAL_LIFE_INSURANCE_REBATE,
  IOwnAnnualLifeInsuranceRebateData,
  UPDATE_OWN_ANNUAL_LIFE_INSURANCE_REBATE,
  IResetOwnAnnualLifeInsuranceData,
  RESET_OWN_ANNUAL_LIFE_INSURANCE_REBATE,
  TLifeInsuranceRebate,
} from './gql'
import {
  Green,
  Divider,
  Center,
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import { pickId } from '../../../../../../utils'
import { Bold } from '../../../../../../components/CommonBox'
import { TMutationFunction } from '../../../../../../types'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { ZivotniPojisteni } from '../../../../../../components/icons/KubikIcons'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import LifeInsuranceRebateOverview from '../../components/LifeInsuranceRebateOverview'
import NumberField from '../../../../../../components/form/NumberField'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Uploader from '../../../../../../components/form/Uploader'
import useForm from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import { LifeInsuranceRebateConditions } from "./LifeInsuranceRebateConditions"
import HelpAdornment from "../../../../../../components/form/HelpAdornment";
import { CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables } from '../../gql/cancelOwnLastAnnualRebate'
import {calculateSharedMaximumFor, getSharedLimits, TSharedLimits} from "../Root";
import SharedRebateLimitInfo from "../../components/SharedRebateLimitInfo";

// interface ISectionsProps {
//   formData: TLifeInsuranceRebate | null
//   updateRebate: TMutationFunction<
//     IUpdateOwnAnnualLifeInsuranceRebateData,
//     TUpdateOwnAnnualLifeInsuranceRebateVariables
//   >
// }

const innerLayoutWidth = 680

const LifeInsuranceRebate: React.FC = () => {
  const { history } = useRouter()
  const { t } = useTranslation()
  const { user, refetch } = useUser()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualLifeInsuranceRebateData,
    TUpdateOwnAnnualLifeInsuranceRebateVariables
  >(UPDATE_OWN_ANNUAL_LIFE_INSURANCE_REBATE, { onError() {} })

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualLifeInsuranceData>(
    RESET_OWN_ANNUAL_LIFE_INSURANCE_REBATE,
    { onError() {} },
  )

  const { data, loading, error } = useQuery<IOwnAnnualLifeInsuranceRebateData>(
    OWN_ANNUAL_LIFE_INSURANCE_REBATE,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )
  const formData = get(
    data,
    'user.annualRebate.lifeInsuranceRebate',
  ) as TLifeInsuranceRebate | null
  const sharedLimits = getSharedLimits(get(data, 'user.annualRebate'), ['lifeInsuranceRebate', 'pensionInsuranceRebate', 'investmentRebate', 'longTermCareRebate'])
  const sharedMaximum = calculateSharedMaximumFor('lifeInsuranceRebate', sharedLimits)

  const { bind, form } = useForm<
    TUpdateOwnAnnualLifeInsuranceRebateVariables['data']
  >(
    formData,
    {
      feeAmount: {
        label: t('common.feeAmount'),
        rule: (data) => (data.type !== 'REMOVE' ? `required|max:${sharedMaximum}` : ''),
        placeholder: sharedMaximum.toLocaleString('cs-CZ') + ' Kč',
        help: t('annualLifeInsuranceRebate.feeAmountHelp')
      },
      feeConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualLifeInsuranceRebate.uploadLabel', { context: "2023" }),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
      contractConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualLifeInsuranceRebate.contractConfirmationFiles'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'lifeInsuranceRebate')

  const cleanRebate = async () => {
    if (formData) {
      const feeConfirmationFiles = formData.feeConfirmationFiles || []
      const contractConfirmationFiles = formData.contractConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            feeAmount: null,
            feeConfirmationFiles: {
              delete: feeConfirmationFiles.map(pickId),
            },
            contractConfirmationFiles: {
              delete: contractConfirmationFiles.map(pickId),
            }
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.lifeInsuranceRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'LIFE_INSURANCE' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/root`)
  }

  // const handleApplyAgain = async () => {
  //   await cleanRebate()
  //   form.setField('type', 'NEW_PERIOD')
  // }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {formData && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'annualLifeInsuranceRebate.sideHint'}>
                <strong>Slevu na soukromé životní pojištění</strong> můžete uplatňovat pouze <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování). <strong>Životních pojištění můžete mít na sebe založených i víc než jedno</strong> a částku k uplatnění můžete sečíst dohromady.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${
              user.data.customer.yearOfAnnualRebates
            }`}
            heading={t('annualLifeInsuranceRebate.heading')}
            subHeading={
              <Trans i18nKey="annualLifeInsuranceRebate.subHeading">
                Chcete-li uplatňovat Slevu na soukromé životní pojištění, <Green>vyplňte výši zaplaceného pojistného</Green> a <Green>nahrajte potvrzení</Green>
              </Trans>
            }
            icon={<ZivotniPojisteni fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelLifeInsurance')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  isPrevCancelled
                ) {
                  return <FormSection bind={bind} sharedLimits={sharedLimits} />
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                      <ChangeRebateChoice
                        label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'
                return (
                  <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <LifeInsuranceRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/annual-rebates/root`}
                  onSubmit={form.submit}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}
export default LifeInsuranceRebate

const FormSection: React.FC<{ bind: (fieldPath: string) => any, sharedLimits: TSharedLimits }> = (props) => {
  const { t } = useTranslation()
  const { bind } = props

  return (
    <>
      <Box maxWidth="100%" width={innerLayoutWidth}>
        <LifeInsuranceRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>
          {() => (
            <NumberField
              inlineLabel={t('annualLifeInsuranceRebate.feeAmountLabel')}
              {...bind('feeAmount')}
            />
          )}
        </Observer>
      </Box>

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <SharedRebateLimitInfo limits={props.sharedLimits} />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Bold maxWidth="100%" width={innerLayoutWidth} display="flex" alignItems="center">
        {t('annualLifeInsuranceRebate.text1')}
        <HelpAdornment inline text={<Trans i18nKey="common.uploadHelp" />} />
      </Bold>

      <Divider maxWidth="100%" width={100} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Box mb={4}>
          <Observer>
            {() => (
              <Uploader
              {...bind('feeConfirmationFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
              />
              )}
          </Observer>
        </Box>

        <Observer>
          {() => (
            <Uploader
              {...bind('contractConfirmationFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>
      </Box>
    </>
  )
}

import { TChildrenRebate } from '../../../../fragments/ChildrenRebate'
import { orderByCreatedAtDesc, months } from '../../../../utils'
import { TBasicRebate } from '../../../../fragments/BasicRebate'
import { TDisabilityRebate } from '../../../../fragments/DisabilityRebate'
import { TStudentRebate } from '../../../../fragments/StudentRebate'
import { TZtppRebate } from '../../../../fragments/ZtppRebate'
import { NexusGenEnums } from 'kubik-server'
import moment, { unitOfTime } from 'moment'
import { useUser } from '../../../../hooks'

import { User } from '../../../../containers/User'

export const isFuture = (date: any, granularity: unitOfTime.StartOf = 'day') =>
  date &&
  moment(date).isValid() &&
  moment(date).isSameOrAfter(moment(), granularity)

export const isHistory = (date: any, granularity: unitOfTime.StartOf = 'day') =>
  date &&
  moment(date).isValid() &&
  moment(date).isSameOrBefore(moment(), granularity)

interface TRebates {
  // basicRebate: TBasicRebate
  // childrenRebate: TChildrenRebate
  studentRebate: TStudentRebate
  disabilityRebate: TDisabilityRebate
  ztppRebate: TZtppRebate
  // lifeInsuranceRebate: TLifeInsuranceRebate
  // pensionInsuranceRebate: TPensionInsuranceRebate
  // examRebate: TExamRebate
  // unionRebate: TUnionRebate
  // giftRebate: TGiftRebate
  // preschoolRebate: TPreschoolRebate
  // spouseRebate: TSpouseRebate
  // loanRebate: TLoanRebate
}

export type TRebate =
  | TStudentRebate
  | TDisabilityRebate
  | TZtppRebate
  | TBasicRebate
// | TChildrenRebate
// | TLifeInsuranceRebate
// | TPensionInsuranceRebate
// | TExamRebate
// | TUnionRebate
// | TGiftRebate
// | TPreschoolRebate
// | TSpouseRebate
// | TLoanRebate

export type TRebateName = keyof TRebates

interface IExtra<T extends keyof TRebates> {
  isPrevDenied: boolean
  isFirstRequest: boolean
  isPrevRemoved: boolean
  isPrevClosedPeriod: boolean
  isCancelable: boolean
  isPrevYear: boolean
  showingCommentOfDenied: string | null
  prevRebate: TRebates[T] | null
  prevPositiveRebate: TRebates[T] | null
}

export const getPrevRebates = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
): T[] => {
  if (Array.isArray(data)) {
    const [, ...prevRebates] = data.sort(orderByCreatedAtDesc)
    return prevRebates
  }
  return []
}

interface IRebate {
  id: string // ID!
  createdAt: any // DateTime!
  closePeriodAt: any | null // DateTime
  commentOfDenied?: string | null // String
  confirmationValidityTo?: any | null // DateTime
  status: NexusGenEnums['RebateStatus'] // RebateStatus!
  type: NexusGenEnums['RequestType'] // RequestType!
}
export const getRebateStats = <T extends IRebate>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: IRebate[] | null,
) => {
  if (Array.isArray(data)) {
    const [rebate, ...prevRebates] = data.sort(orderByCreatedAtDesc)

    if (rebate) {
      const prevRebate = (prevRebates[0] as unknown) as T
      const prevPositiveRebates = prevRebates.filter(
        (r) => r.status === 'CONFIRMED' && r.type !== 'REMOVE',
      )
      // Poslední schválená žádost, která není REMOVE - tzn. byla zahrnuta do měsíčního zůčtování
      const prevPositiveRebate = (prevPositiveRebates[0] as unknown) as T

      const isFirstRequest = prevRebates.length === 0
      const isPrevDenied = prevRebate && prevRebate.status === 'DENIED'
      const isPrevCanceled =
        prevRebate && prevRebate.status === 'CANCELED' && !prevPositiveRebate

      const isPrevPositiveClosedPeriod =
        prevPositiveRebate && prevPositiveRebate.type === 'CLOSE_PERIOD'

      // Existuje předchozí schválená sleva ale platnost potvrzení již vypršelo
      const confirmationValidityExpired =
        prevPositiveRebate &&
        isHistory(prevPositiveRebate.confirmationValidityTo)

      // Existuje předchozí schválená žádost o ukončení slevy která již nabyla platnost
      const closePeriodTookEffect =
        isPrevPositiveClosedPeriod &&
        prevPositiveRebate &&
        isHistory(prevPositiveRebate.closePeriodAt)

      const overallConfirmed =
        prevPositiveRebate &&
        !closePeriodTookEffect &&
        !confirmationValidityExpired

      return {
        overallConfirmed,
        prevRebates,
        isFirstRequest,
        isPrevDenied,
        isPrevCanceled,
        isPrevPositiveClosedPeriod,
        confirmationValidityExpired,
        closePeriodTookEffect,
        isCancelable: rebate.status === 'APPLY' || isPrevDenied,
        prevRebate: (prevRebate || null) as T | null,
        prevPositiveRebate: (prevPositiveRebate || null) as T | null,
        showingCommentOfDenied:
          (prevRebate &&
            isPrevDenied &&
            rebate.status !== 'APPLY' &&
            prevRebate.commentOfDenied) ||
          null,
        // isPrevYear: Boolean(
        //   prevRebate &&
        //     prevRebate.settlementRequest &&
        //     prevRebate.settlementRequest.type === 'MONTHLY_PREV_YEAR',
        // ),
      }
    }
  }

  return {
    prevRebates: [],
    overallConfirmed: false,
    isFirstRequest: true,
    isPrevDenied: false,
    isPrevPositiveClosedPeriod: false,
    confirmationValidityExpired: false,
    closePeriodTookEffect: false,
    isCancelable: false,
    prevRebate: null as T | null,
    prevPositiveRebate: null as T | null,
    showingCommentOfDenied: null,
  }
}

export const getChildrenRebateStats = (
  data: TChildrenRebate[] | null,
  year: number,
) => {
  if (Array.isArray(data)) {
    const [rebate, ...prevRebates] = data.sort(orderByCreatedAtDesc)

    if (rebate) {
      const prevRebate = prevRebates[0]
      const prevPositiveRebates = prevRebates.filter(
        (r) => r.status === 'CONFIRMED' && r.type !== 'REMOVE',
      )
      // Poslední schválená žádost, která není REMOVE - tzn. byla zahrnuta do měsíčního zůčtování
      const prevPositiveRebate = prevPositiveRebates[0]

      const isFirstRequest = prevRebates.length === 0
      const isPrevDenied =
        prevRebate &&
        (prevRebate.otherParentStatus === 'DENIED' ||
          (prevRebate.children &&
            prevRebate.children.some((ch) => ch.status === 'DENIED')))

      const overallConfirmed = Boolean(
        prevPositiveRebate &&
          prevPositiveRebate.children &&
          prevPositiveRebate.children
            .reduce<NexusGenEnums['Month'][]>(
              (prev, curr) => prev.concat(curr.applyInMonths),
              [],
            )
            .some(
              (mon) =>
                mon &&
                moment([year, months.indexOf(mon)]).isSameOrAfter(
                  new Date(),
                  'month',
                ),
            ),
      )

      return {
        prevRebates,
        overallConfirmed,
        isFirstRequest,
        isPrevDenied,
        isCancelable: rebate.status === 'APPLY' || isPrevDenied,
        prevRebate: prevRebate || null,
        prevPositiveRebate: prevPositiveRebate || null,
        showingCommentOfDenied:
          (prevRebate &&
            isPrevDenied &&
            rebate.status !== 'APPLY' &&
            prevRebate.commentOfDenied) ||
          null,
      }
    }
  }

  return {
    prevRebates: [],
    overallConfirmed: false,
    isFirstRequest: true,
    isPrevDenied: false,
    isCancelable: false,
    prevRebate: null,
    prevPositiveRebate: null,
    showingCommentOfDenied: null,
  }
}

interface Arguments {
  alter?: boolean
  confirmationValidityFrom: any
  user: ReturnType<typeof useUser>['user']
}

/**
 * @param user
 * @param year
 * @param justProvidedAffidavitFiles already has valid affadivitFiles
 */
export const getApplyFromInYear = (
  user: User,
  year: number,
  justProvidedAffidavitFiles: boolean = false,
): Date => {
  if (moment().year() === year && !user.allowAlter()) {
    return justProvidedAffidavitFiles
      ? moment(user.data.minRebateDateIfAffidavit).toDate()
      : moment(user.data.minRebateDate).toDate()
  } else {
    return new Date(year, 0, 1)
  }
}

import {
  IUpdateOwnAnnualGiftRebateData,
  TUpdateOwnAnnualGiftRebateVariables,
  OWN_ANNUAL_GIFT_REBATE,
  IOwnAnnualGiftRebateData,
  UPDATE_OWN_ANNUAL_GIFT_REBATE,
  IResetOwnAnnualGiftRebateData,
  RESET_OWN_ANNUAL_GIFT_REBATE,
  TGiftRebate,
} from './gql'
import {
  Green,
  Divider,
  Center,
  Error,
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import { pickId } from '../../../../../../utils'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Dary2 from '../../../../../../components/icons/Dary2'
import Dary from '../../../../../../components/icons/Dary'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GiftRebateOverview from '../../components/GiftRebateOverview'
import GiftTable from './GiftTable'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import Uploader from '../../../../../../components/form/Uploader'
import useForm, { Form } from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import { GiftRebateConditions } from './GiftRebateConditions'
import { CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables } from '../../gql/cancelOwnLastAnnualRebate'

const innerLayoutWidth = 680

const GiftRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualGiftRebateData,
    TUpdateOwnAnnualGiftRebateVariables
  >(UPDATE_OWN_ANNUAL_GIFT_REBATE, { onError() {} })

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualGiftRebateData>(RESET_OWN_ANNUAL_GIFT_REBATE, {
    onError() {},
  })

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const { data, loading, error } = useQuery<IOwnAnnualGiftRebateData>(
    OWN_ANNUAL_GIFT_REBATE,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )
  const formData = get(
    data,
    'user.annualRebate.giftRebate',
  ) as TGiftRebate | null

  const { bind, form } = useForm<TUpdateOwnAnnualGiftRebateVariables['data']>(
    formData,
    {
      gifts: {
        type: 'multi_relation',
        label: t('common.gifts'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      giftConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualGiftRebate.uploadLabel'),
        help: t('common.uploadHelp'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'giftRebate')

  const validateTotalAmountMin = () => {
    const gifts: { amount: number }[] = form.getValue('gifts')
    const totalAmount = gifts.reduce(
      (prev, curr) => prev + (curr.amount || 0),
      0,
    )
    return gifts.length === 0 || totalAmount >= 1000
  }

  const cleanRebate = async () => {
    if (formData) {
      const files = formData.giftConfirmationFiles || []
      const gifts = formData.gifts || []
      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            giftConfirmationFiles: {
              delete: files.map(pickId),
            },
            gifts: {
              delete: gifts.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.giftRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'GIFT' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/root`)
  }

  // const handleApplyAgain = async () => {
  //   await cleanRebate()
  //   form.setField('type', 'NEW_PERIOD')
  // }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {formData && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey="annualGiftRebate.sideHint">
                <strong>Slevu na dar</strong> můžete uplatňovat pouze <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování). Slevu můžete <strong>uplatnit i u darů ve formě služby, věci</strong> nebo <strong>nemovitosti</strong>, pokud je možné určit jejich hodnotu. Pokud to možné není a vy si přesto tento dar chcete od základu daně odečíst, je nutné majetek ocenit, a to podle zákona o oceňování majetku.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${
              user.data.customer.yearOfAnnualRebates
            }`}
            heading={t('annualGiftRebate.heading')}
            subHeading={
              <Trans i18nKey="annualGiftRebate.subHeading">
                Chcete-li uplatňovat slevu na dary, <Green>vyplňte údaje o daru</Green> a <Green>nahrajte potvrzení</Green>
              </Trans>
            }
            icon={<Dary2 fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelGift')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  isPrevCancelled
                ) {
                  return (
                    <FormSection
                      bind={bind}
                      form={form}
                      validateTotalAmountMin={validateTotalAmountMin}
                    />
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                      <ChangeRebateChoice
                        label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'
                return (
                  <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <GiftRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/annual-rebates/root`}
                  onSubmit={() => validateTotalAmountMin() && form.submit()}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}
export default GiftRebate

const FormSection: React.FC<{
  bind: (fieldPath: string) => any
  form: Form
  validateTotalAmountMin: () => boolean
}> = (props) => {
  const { t } = useTranslation()
  const { bind, form, validateTotalAmountMin } = props

  return (
    <>
      <Box maxWidth="100%" width={innerLayoutWidth}>
        <GiftRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Typography align="center">
        <Trans i18nKey="annualGiftRebate.text1">
          K uplatnění daru je nutné vyplnit popis a hodnotu daru.
          <br />
          To provedete kliknutím na <Green>Přidat dar</Green>.
        </Trans>
      </Typography>

      <Box display="flex" flexDirection="column" justifyContent="center" maxWidth="100%" width={innerLayoutWidth}>
        <Observer>{() => <GiftTable {...bind('gifts')} />}</Observer>
      </Box>

      <Observer>
        {() => {
          const giftsTouched = form.touched.get('gifts')
          const gifts = form.getValue('gifts')

          if (giftsTouched && gifts.length < 1) {
            return <Error>{t('annualGiftRebate.giftsAreRequired')}</Error>
          }

          if (!validateTotalAmountMin()) {
            return <Error>{t('annualGiftRebate.amountMinError')}</Error>
          }

          return <React.Fragment />
        }}
      </Observer>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>
          {() => <Uploader {...bind('giftConfirmationFiles')} showLabel multiple />}
        </Observer>
      </Box>
    </>
  )
}

import {
  CANCEL_OWN_LAST_ANNUAL_CHILDREN_REBATE,
  IOwnAnnualChildrenRebateData,
  IResetOwnAnnualChildrenData,
  IUpdateOwnAnnualChildrenRebateData,
  OWN_ANNUAL_CHILDREN_REBATE,
  RESET_OWN_ANNUAL_CHILDREN_REBATE,
  TUpdateOwnAnnualChildrenRebateVariables,
  UPDATE_OWN_ANNUAL_CHILDREN_REBATE,
} from './gql'
import {
  Center,
  Divider,
  Green,
  Column,
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import {
  ChildrenSection,
  useChildrenRebateType,
  PrevRebate,
} from '../../../../../../shared/Children'
import { Form } from '../../../../../../hooks/useForm'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { NexusGenFieldTypes } from 'kubik-server'
import { Observer } from 'mobx-react'
import { pickId } from '../../../../../../utils'
import { TChildrenRebate } from '../../../../../../fragments/ChildrenRebate'
import { useForm, useRouter, useUser } from '../../../../../../hooks'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import { useWorkEnum } from '../../../../../../hooks/useEnumList'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Deti from '../../../../../../components/icons/Deti'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import ChildrenRebateOverview from '../../components/ChildrenRebateOverview'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import React from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Select from '../../../../../../components/form/Select'
import Switch from '../../../../../../components/form/Switch'
import TextField from '../../../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import Uploader from '../../../../../../components/form/Uploader'
import { ChildrenRebateConditions } from '../../../../../../shared/Children/ChildrenRebateConditions'

const ChildrenRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()
  const { list: workList } = useWorkEnum()

  const [type, setType, initType] = useChildrenRebateType()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualChildrenRebateData,
    TUpdateOwnAnnualChildrenRebateVariables
  >(UPDATE_OWN_ANNUAL_CHILDREN_REBATE, { onError() {} })

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean>(CANCEL_OWN_LAST_ANNUAL_CHILDREN_REBATE)

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualChildrenData>(
    RESET_OWN_ANNUAL_CHILDREN_REBATE,
    {
      onError() {},
    },
  )

  const { data, loading, error } = useQuery<IOwnAnnualChildrenRebateData>(
    OWN_ANNUAL_CHILDREN_REBATE,
    {
      fetchPolicy: 'cache-and-network',
      onError() {},
      onCompleted(data) {
        initType(data.user.annualRebate.childrenRebate)
      },
    },
  )
  const year = get(data, 'user.annualRebate.year')
  const formData: TChildrenRebate =
    get(data, 'user.annualRebate.childrenRebate') || {
      otherParentNourishes: true
    }

  const isSupport = user.isSupportReincarnation()
  const { bind, form } = useForm<
    TUpdateOwnAnnualChildrenRebateVariables['data']
  >(
    formData,
    {
      children: {
        type: 'multi_relation',
        label: t('common.children'),
        rule: (data) =>
          data.type !== 'REMOVE'
            ? (isSupport ? 'required' : 'required|children_order|children_ztpp_required')
            : '',
        messages: {
          required: t('monthlyChildrenRebate.childrenAreRequired'),
        },
      },
      otherParentConfirmationFiles: {
        type: 'multi_relation',
        label: t('monthlyChildrenRebate.otherParentConfirmationLabel'),
        help: t('common.uploadHelp'),
        rule: (data, form) => {
          const nourishes = form.getValue('otherParentNourishes')
          const applying = form.getValue('otherParentApplying')
          if (nourishes && !applying) {
            return 'required'
          } else {
            return ''
          }
        },
        isFileRelation: true
      },
      otherParentNourishes: {
        label: t('monthlyChildrenRebate.otherParentNourishes'),
        help: t('monthlyChildrenRebate.otherParentNourishesHelp'),
      },
      otherParentApplying: {
        label: t('monthlyChildrenRebate.otherParentApplying'),
      },
      otherParentFirstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstname') + '...',
        rule: (data) => (Boolean(data.otherParentNourishes) ? 'required' : ''),
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentLastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastname') + '...',
        rule: (data) => (Boolean(data.otherParentNourishes) ? 'required' : ''),
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentNationalIDNumber: {
        label: t('common.nationalIDNumber'),
        placeholder: t('common.nationalIDNumber', {
          context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : undefined,
        }) + '...',
        rule: (data) =>
          Boolean(data.otherParentNourishes)
            ? 'required' //|regex:/^([0-9]{9,10})$/'
            : '', // 'regex:/^([0-9]{9,10})$/',
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentAddress: {
        label: t('common.permanentAddress'),
        placeholder: t('common.permanentAddressPlaceholder'),
        rule: (data) => (Boolean(data.otherParentNourishes) ? 'required' : ''),
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentWork: {
        list: workList,
        placeholder: t('common.selectWork'),
        label: t('common.work'),
        rule: (data) => (Boolean(data.otherParentNourishes) ? 'required' : ''),
        omitOnSubmit: (data) => data.otherParentNourishes !== true,
      },
      otherParentWorkName: {
        label: t('annualRebatePreviousEmployers.companyName'),
        rule: (data) =>
          Boolean(data.otherParentNourishes) &&
          data.otherParentWork === 'EMPLOYER'
            ? 'required'
            : '',
        omitOnSubmit: (data) =>
          data.otherParentNourishes !== true ||
          data.otherParentWork !== 'EMPLOYER',
      },
      otherParentWorkAddress: {
        label: t('common.employerAddress'),
        rule: (data) =>
          Boolean(data.otherParentNourishes) &&
          data.otherParentWork === 'EMPLOYER'
            ? 'required'
            : '',
        omitOnSubmit: (data) =>
          data.otherParentNourishes !== true ||
          data.otherParentWork !== 'EMPLOYER',
      },
    },
    {
      async onSubmit(data, form) {
        const children = form.getValue('children')

        // změna schvalování dětí - vrácení schválení celé slevy
        // pokud je dítě zamítnuté a není odstraněné a zároveň jsem v něm neudělal žádnou změnu
        // tzn. není v datech, ale jen ve formu, tak je musím nastavit na APPLY.
        if (!data.children && children) {
          const updateChildren: any[] = []
          children
            .filter(
              (ch) =>
                !(
                  ch.type === 'REMOVE' &&
                  (ch.status === 'DENIED' || ch.status === 'CONFIRMED')
                ),
            )
            .map((ch) => {
              updateChildren.push({
                where: {
                  id: ch.id,
                },
                data: {
                  status: 'APPLY',
                },
              })
            })

          if (updateChildren.length > 0) {
            data = {
              ...data,
              children: {
                update: updateChildren,
              },
            }
          }
        }

        if (children.some((ch) => ch.status === 'NEW')) {
          return t('monthlyChildrenRebate.childrenAreRequired')
        }

        try {
          const { errors } = await updateRebate({
            variables: {
              data: {
                ...data,
                status: 'APPLY',
                commentOfDenied: null,
              },
            },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  // const { status } = useChildrenRebateStats(
  //   get(data, 'user.annualRebate.childrenRebates'),
  // )

  // const showForm = status === 'NEW' || status === 'DENIED' || type === 'APPLY'

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      isCancelable,
      isPrevCancelled,
      isPrevYear
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'childrenRebate')

  const cleanRebate = async () => {
    if (formData) {
      const children = formData.children || []
      const otherParentConfirmationFiles =
        formData.otherParentConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            children: { delete: children.map(pickId) },
            otherParentStatus: 'NEW',
            otherParentNourishes: null,
            otherParentAddress: null,
            otherParentApplying: null, // default
            otherParentFirstname: null,
            otherParentLastname: null,
            otherParentNationalIDNumber: null,
            otherParentWork: null,
            otherParentWorkAddress: null,
            otherParentWorkName: null,
            otherParentConfirmationFiles: {
              delete: otherParentConfirmationFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.childrenRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'CHILDREN' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
  }

  const handleApplyAgain = async () => {
    // await cleanRebate()
    await resetRebate()
    form.setField('type', 'NEW_PERIOD')
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  // const deniedChildren = ((formData && formData.children) || []).filter(
  //   (ch) => ch.status === 'DENIED',
  // )

  const deniedParent =
    formData && formData.otherParentStatus === 'DENIED' ? formData : null

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {data && data.user && (
        <Fade>
          <RebateLayout
            commentOfDenied={
              deniedParent && deniedParent.otherParentCommentOfDenied
            }
            sideHint={
              <Trans i18nKey="monthlyChildrenRebate.sideHint">
                <strong>Slevu na děti</strong> můžete uplatňovat <strong>měsíčně</strong> nebo <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování &gt; krok Měsíční slevy dodatečné uplatnění). Uplatňujete-li slevu na dítě, které je studentem, a jeho <strong>vzdělávání pokračuje bez přerušení</strong>, slevu je možné uplatňovat i v <strong>průběhu letních prázdnin</strong>.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${year}`}
            heading={t('monthlyChildrenRebate.heading')}
            subHeading={
              <Trans i18nKey="monthlyChildrenRebate.subHeading">
                Chcete-li uplatňovat Slevu na invaliditu, tak je nutné
                <Green>vyplnit platnost potvrzení</Green> a
                <Green>nahrát potvrzení o invaliditě</Green>
              </Trans>
            }
            icon={<Deti fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelChildren')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  (isPrevRemoved && !isPrevYear) ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  isPrevCancelled
                ) {
                  return (
                    <FormSection
                      bind={bind}
                      form={form}
                      year={year}
                      prevRebates={prevRebates}
                    />
                  )
                }

                return (
                  <Center width={680}>
                    <ChangeRebateButton>
                      {isPrevYear ? (
                        <ChangeRebateChoice
                        variant="APPLY_AGAIN"
                        onClick={handleApplyAgain}
                      />
                      ) : (
                        <ChangeRebateChoice
                          variant="REMOVE"
                          onClick={handleRemove}
                        />
                      )}

                      {!isPrevYear ? (
                        <ChangeRebateChoice
                          label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                          onClick={handleAlter}
                        />
                      ) : null}
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>
            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'
                return (
                  <Box width={680} mt={6} mb={2}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate key={rebate.id} data={rebate}>
                          <ChildrenRebateOverview data={rebate} dense />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => (
                <RebateActions
                  backTo={`/${user.data.id}/annual-rebates/rootMonthly`}
                  onSubmit={form.submit}
                  isDirty={form.state.isDirty}
                  formLoading={form.state.loading}
                />
              )}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default ChildrenRebate

const FormSection: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind: (fieldPath: string) => any
  prevRebates: NexusGenFieldTypes['ChildrenRebate'][]
  form: Form
  year: number
}> = (props) => {
  const { bind, prevRebates, form, year } = props

  const newPeriod = form.getValue('type') === 'NEW_PERIOD'

  return (
    <>
      <Box maxWidth="100%" width={680}>
        <ChildrenRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={680} my={4} />

      <Box maxWidth="100%" width={680}>
        <Typography align="center">
          <Trans i18nKey="monthlyChildrenRebate.text1">
            K uplatnění slevy je nutné vyplnit údaje o všech vyživovaných dětech.
            <br />
            To provedete kliknutím na <Green>Přidat dítě</Green>
          </Trans>
        </Typography>
      </Box>

      <Observer>
        {() => (
          <ChildrenSection
            {...bind('children')}
            prevRebates={prevRebates}
            year={year}
            variant="ANNUAL"
            newPeriod={newPeriod}
            initialValue={(form.initials && form.initials.children) || []}
          />
        )}
      </Observer>

        <>
          <Divider maxWidth="100%" width={680} my={4} />

          <Box maxWidth="100%" width={680}>
            <Observer>
              {() => <Switch {...bind('otherParentNourishes')} />}
            </Observer>
          </Box>

          <Observer>
            {() => {
              // const nourishes = form.getValue('otherParentNourishes')
              const applying = form.getValue('otherParentApplying')

              const otherParentConfirmationFileAllowed = !applying

              return (
                <>
                  {Boolean(form.getValue('otherParentNourishes')) && (
                    <>
                      <Column maxWidth="100%" width={480}>
                        <Divider maxWidth="100%" width={200} my={4} />

                        <Box mb={4}>
                          <Typography align='center'>
                            <Trans i18nKey="monthlyChildrenRebate.otherParentNourishesHeading">Vyplňte údaje o <Green>druhém vyživovateli</Green>:</Trans>
                          </Typography>
                        </Box>

                        <TextField {...bind('otherParentFirstname')} />
                        <TextField {...bind('otherParentLastname')} />
                        <TextField {...bind('otherParentNationalIDNumber')} />
                        <TextField {...bind('otherParentAddress')} />
                        <Select {...bind('otherParentWork')} valueField="id" hideEmptyValue />

                        {form.getValue('otherParentWork') === 'EMPLOYER' && (
                          <>
                            <TextField {...bind('otherParentWorkName')} />
                            <TextField {...bind('otherParentWorkAddress')} />
                          </>
                        )}

                        <Switch {...bind('otherParentApplying')} />
                      </Column>
                    </>
                  )}

                  {otherParentConfirmationFileAllowed ? (
                    <>
                      <Divider maxWidth="100%" width={680} my={4} />

                      <Box maxWidth="100%" width={680}>
                        <Uploader
                          showLabel
                          multiple
                          {...bind('otherParentConfirmationFiles')}
                        />
                      </Box>
                    </>
                  ) : null}
                </>
              )
            }}
          </Observer>
        </>
    </>
  )
}

import { useTranslation } from 'react-i18next'
import { NexusGenEnums } from 'kubik-server'

export type IEnumList<TEnum> = {
  id: TEnum
  text: string
}[]

const createTranslate = <TEnum>(list: IEnumList<TEnum>) => (
  value: TEnum | null,
) => {
  const item = list.find((item) => item.id === value)
  return item && item.text
}

export const useIDTypeEnum = () => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums['IDType']> = [
    { id: 'IDENTITY_CARD', text: t('common.identityCard') },
    { id: 'PASSPORT', text: t('common.passport') },
    { id: 'DRIVING_LICENCE', text: t('common.drivingLicence') },
    { id: 'OTHER', text: t('common.other') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['IDType']>(list),
  }
}

export const useGenderEnum = () => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums['Gender']> = [
    { id: 'FEMALE', text: t('common.female') },
    { id: 'MALE', text: t('common.male') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['Gender']>(list),
  }
}

export const useCountryEnum = () => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums['Country']> = [
    { id: 'AF', text: t('country.AF') },
    { id: 'AL', text: t('country.AL') },
    { id: 'DZ', text: t('country.DZ') },
    { id: 'AS', text: t('country.AS') },
    { id: 'AD', text: t('country.AD') },
    { id: 'AO', text: t('country.AO') },
    { id: 'AI', text: t('country.AI') },
    { id: 'AQ', text: t('country.AQ') },
    { id: 'AG', text: t('country.AG') },
    { id: 'AR', text: t('country.AR') },
    { id: 'AM', text: t('country.AM') },
    { id: 'AW', text: t('country.AW') },
    { id: 'AU', text: t('country.AU') },
    { id: 'AT', text: t('country.AT') },
    { id: 'AZ', text: t('country.AZ') },
    { id: 'BH', text: t('country.BH') },
    { id: 'BD', text: t('country.BD') },
    { id: 'BB', text: t('country.BB') },
    { id: 'BY', text: t('country.BY') },
    { id: 'BE', text: t('country.BE') },
    { id: 'BZ', text: t('country.BZ') },
    { id: 'BJ', text: t('country.BJ') },
    { id: 'BM', text: t('country.BM') },
    { id: 'BT', text: t('country.BT') },
    { id: 'BO', text: t('country.BO') },
    { id: 'BQ', text: t('country.BQ') },
    { id: 'BA', text: t('country.BA') },
    { id: 'BW', text: t('country.BW') },
    { id: 'BV', text: t('country.BV') },
    { id: 'BR', text: t('country.BR') },
    { id: 'IO', text: t('country.IO') },
    { id: 'BN', text: t('country.BN') },
    { id: 'BG', text: t('country.BG') },
    { id: 'BF', text: t('country.BF') },
    { id: 'BI', text: t('country.BI') },
    { id: 'KH', text: t('country.KH') },
    { id: 'CM', text: t('country.CM') },
    { id: 'CA', text: t('country.CA') },
    { id: 'CV', text: t('country.CV') },
    { id: 'KY', text: t('country.KY') },
    { id: 'CF', text: t('country.CF') },
    { id: 'TD', text: t('country.TD') },
    { id: 'CL', text: t('country.CL') },
    { id: 'CN', text: t('country.CN') },
    { id: 'CX', text: t('country.CX') },
    { id: 'CC', text: t('country.CC') },
    { id: 'CO', text: t('country.CO') },
    { id: 'KM', text: t('country.KM') },
    { id: 'CK', text: t('country.CK') },
    { id: 'CR', text: t('country.CR') },
    { id: 'HR', text: t('country.HR') },
    { id: 'CU', text: t('country.CU') },
    { id: 'CW', text: t('country.CW') },
    { id: 'CY', text: t('country.CY') },
    { id: 'CZ', text: t('country.CZ') },
    { id: 'CD', text: t('country.CD') },
    { id: 'DK', text: t('country.DK') },
    { id: 'DJ', text: t('country.DJ') },
    { id: 'DM', text: t('country.DM') },
    { id: 'DO', text: t('country.DO') },
    { id: 'TL', text: t('country.TL') },
    { id: 'EC', text: t('country.EC') },
    { id: 'EG', text: t('country.EG') },
    { id: 'SV', text: t('country.SV') },
    { id: 'GQ', text: t('country.GQ') },
    { id: 'ER', text: t('country.ER') },
    { id: 'EE', text: t('country.EE') },
    { id: 'ET', text: t('country.ET') },
    { id: 'FK', text: t('country.FK') },
    { id: 'FO', text: t('country.FO') },
    { id: 'FM', text: t('country.FM') },
    { id: 'FJ', text: t('country.FJ') },
    { id: 'FI', text: t('country.FI') },
    { id: 'FR', text: t('country.FR') },
    { id: 'GF', text: t('country.GF') },
    { id: 'PF', text: t('country.PF') },
    { id: 'TF', text: t('country.TF') },
    { id: 'GA', text: t('country.GA') },
    { id: 'GE', text: t('country.GE') },
    { id: 'DE', text: t('country.DE') },
    { id: 'GH', text: t('country.GH') },
    { id: 'GI', text: t('country.GI') },
    { id: 'GR', text: t('country.GR') },
    { id: 'GL', text: t('country.GL') },
    { id: 'GD', text: t('country.GD') },
    { id: 'GP', text: t('country.GP') },
    { id: 'GU', text: t('country.GU') },
    { id: 'GT', text: t('country.GT') },
    { id: 'GG', text: t('country.GG') },
    { id: 'GN', text: t('country.GN') },
    { id: 'GW', text: t('country.GW') },
    { id: 'GY', text: t('country.GY') },
    { id: 'HT', text: t('country.HT') },
    { id: 'HM', text: t('country.HM') },
    { id: 'VA', text: t('country.VA') },
    { id: 'HN', text: t('country.HN') },
    { id: 'HK', text: t('country.HK') },
    { id: 'HU', text: t('country.HU') },
    { id: 'IS', text: t('country.IS') },
    { id: 'IN', text: t('country.IN') },
    { id: 'ID', text: t('country.ID') },
    { id: 'IR', text: t('country.IR') },
    { id: 'IQ', text: t('country.IQ') },
    { id: 'IM', text: t('country.IM') },
    { id: 'IL', text: t('country.IL') },
    { id: 'IT', text: t('country.IT') },
    { id: 'CI', text: t('country.CI') },
    { id: 'JM', text: t('country.JM') },
    { id: 'JP', text: t('country.JP') },
    { id: 'JE', text: t('country.JE') },
    { id: 'JO', text: t('country.JO') },
    { id: 'KZ', text: t('country.KZ') },
    { id: 'KE', text: t('country.KE') },
    { id: 'KI', text: t('country.KI') },
    { id: 'KW', text: t('country.KW') },
    { id: 'KG', text: t('country.KG') },
    { id: 'LA', text: t('country.LA') },
    { id: 'LV', text: t('country.LV') },
    { id: 'LB', text: t('country.LB') },
    { id: 'LS', text: t('country.LS') },
    { id: 'LR', text: t('country.LR') },
    { id: 'LY', text: t('country.LY') },
    { id: 'LI', text: t('country.LI') },
    { id: 'LT', text: t('country.LT') },
    { id: 'LU', text: t('country.LU') },
    { id: 'MO', text: t('country.MO') },
    { id: 'MG', text: t('country.MG') },
    { id: 'MW', text: t('country.MW') },
    { id: 'MY', text: t('country.MY') },
    { id: 'MV', text: t('country.MV') },
    { id: 'ML', text: t('country.ML') },
    { id: 'MT', text: t('country.MT') },
    { id: 'MH', text: t('country.MH') },
    { id: 'MQ', text: t('country.MQ') },
    { id: 'MR', text: t('country.MR') },
    { id: 'MU', text: t('country.MU') },
    { id: 'YT', text: t('country.YT') },
    { id: 'MX', text: t('country.MX') },
    { id: 'MD', text: t('country.MD') },
    { id: 'MC', text: t('country.MC') },
    { id: 'MN', text: t('country.MN') },
    { id: 'ME', text: t('country.ME') },
    { id: 'MS', text: t('country.MS') },
    { id: 'MA', text: t('country.MA') },
    { id: 'MZ', text: t('country.MZ') },
    { id: 'MM', text: t('country.MM') },
    { id: 'NA', text: t('country.NA') },
    { id: 'NR', text: t('country.NR') },
    { id: 'NP', text: t('country.NP') },
    { id: 'NL', text: t('country.NL') },
    { id: 'NC', text: t('country.NC') },
    { id: 'NZ', text: t('country.NZ') },
    { id: 'NI', text: t('country.NI') },
    { id: 'NE', text: t('country.NE') },
    { id: 'NG', text: t('country.NG') },
    { id: 'NU', text: t('country.NU') },
    { id: 'NF', text: t('country.NF') },
    { id: 'KP', text: t('country.KP') },
    { id: 'MP', text: t('country.MP') },
    { id: 'NO', text: t('country.NO') },
    { id: 'OM', text: t('country.OM') },
    { id: 'PK', text: t('country.PK') },
    { id: 'PW', text: t('country.PW') },
    { id: 'PS', text: t('country.PS') },
    { id: 'PA', text: t('country.PA') },
    { id: 'PG', text: t('country.PG') },
    { id: 'PY', text: t('country.PY') },
    { id: 'PE', text: t('country.PE') },
    { id: 'PH', text: t('country.PH') },
    { id: 'PN', text: t('country.PN') },
    { id: 'PL', text: t('country.PL') },
    { id: 'PT', text: t('country.PT') },
    { id: 'PR', text: t('country.PR') },
    { id: 'QA', text: t('country.QA') },
    { id: 'IE', text: t('country.IE') },
    { id: 'XK', text: t('country.XK') },
    { id: 'MK', text: t('country.MK') },
    { id: 'CG', text: t('country.CG') },
    { id: 'RO', text: t('country.RO') },
    { id: 'RU', text: t('country.RU') },
    { id: 'RW', text: t('country.RW') },
    { id: 'RE', text: t('country.RE') },
    { id: 'BL', text: t('country.BL') },
    { id: 'SH', text: t('country.SH') },
    { id: 'KN', text: t('country.KN') },
    { id: 'LC', text: t('country.LC') },
    { id: 'MF', text: t('country.MF') },
    { id: 'PM', text: t('country.PM') },
    { id: 'VC', text: t('country.VC') },
    { id: 'WS', text: t('country.WS') },
    { id: 'SM', text: t('country.SM') },
    { id: 'SA', text: t('country.SA') },
    { id: 'SN', text: t('country.SN') },
    { id: 'RS', text: t('country.RS') },
    { id: 'SC', text: t('country.SC') },
    { id: 'SL', text: t('country.SL') },
    { id: 'SG', text: t('country.SG') },
    { id: 'SX', text: t('country.SX') },
    { id: 'SK', text: t('country.SK') },
    { id: 'SI', text: t('country.SI') },
    { id: 'SB', text: t('country.SB') },
    { id: 'SO', text: t('country.SO') },
    { id: 'ZA', text: t('country.ZA') },
    { id: 'GS', text: t('country.GS') },
    { id: 'KR', text: t('country.KR') },
    { id: 'SS', text: t('country.SS') },
    { id: 'ES', text: t('country.ES') },
    { id: 'LK', text: t('country.LK') },
    { id: 'SD', text: t('country.SD') },
    { id: 'SR', text: t('country.SR') },
    { id: 'SJ', text: t('country.SJ') },
    { id: 'SZ', text: t('country.SZ') },
    { id: 'SE', text: t('country.SE') },
    { id: 'CH', text: t('country.CH') },
    { id: 'SY', text: t('country.SY') },
    { id: 'ST', text: t('country.ST') },
    { id: 'TW', text: t('country.TW') },
    { id: 'TJ', text: t('country.TJ') },
    { id: 'TZ', text: t('country.TZ') },
    { id: 'TH', text: t('country.TH') },
    { id: 'BS', text: t('country.BS') },
    { id: 'GM', text: t('country.GM') },
    { id: 'TG', text: t('country.TG') },
    { id: 'TK', text: t('country.TK') },
    { id: 'TO', text: t('country.TO') },
    { id: 'TT', text: t('country.TT') },
    { id: 'TN', text: t('country.TN') },
    { id: 'TR', text: t('country.TR') },
    { id: 'TM', text: t('country.TM') },
    { id: 'TC', text: t('country.TC') },
    { id: 'TV', text: t('country.TV') },
    { id: 'UG', text: t('country.UG') },
    { id: 'UA', text: t('country.UA') },
    { id: 'AE', text: t('country.AE') },
    { id: 'GB', text: t('country.GB') },
    { id: 'US', text: t('country.US') },
    { id: 'UM', text: t('country.UM') },
    { id: 'UY', text: t('country.UY') },
    { id: 'UZ', text: t('country.UZ') },
    { id: 'VU', text: t('country.VU') },
    { id: 'VE', text: t('country.VE') },
    { id: 'VN', text: t('country.VN') },
    { id: 'VG', text: t('country.VG') },
    { id: 'VI', text: t('country.VI') },
    { id: 'WF', text: t('country.WF') },
    { id: 'EH', text: t('country.EH') },
    { id: 'YE', text: t('country.YE') },
    { id: 'ZM', text: t('country.ZM') },
    { id: 'ZW', text: t('country.ZW') },
    { id: 'AX', text: t('country.AX') },
    { id: 'XB', text: t('country.XB') },
  ]

  list.sort(function(a, b) {
    const x = a.text.toLowerCase()
    const y = b.text.toLowerCase()
    if (x < y) return -1
    if (x > y) return 1
    return 0
  })

  const czIndex = list.findIndex((item) => item.id === 'CZ')
  const cz = list.splice(czIndex, 1)[0]
  list.unshift(cz)

  return {
    list,
    translate: createTranslate<NexusGenEnums['Country']>(list),
  }
}

export const useRoleEnum = () => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums['Role']> = [
    { id: 'ACCOUNTANT', text: t('role.accountant') },
    { id: 'EMPLOYEE', text: t('role.employee') },
    { id: 'IMPLEMENTATOR', text: t('role.implementator') },
    { id: 'KEEPER', text: t('role.keeper') },
    { id: 'SUPPORT', text: t('role.support') },
    { id: 'VIEWER', text: t('role.viewer') },
    { id: 'SUPERADMIN', text: t('role.superadmin') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['Role']>(list),
  }
}

export const useChildOrderEnum = () => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums['ChildOrder']> = [
    { id: 'FIRST', text: t('childOrder.first') },
    { id: 'SECOND', text: t('childOrder.second') },
    { id: 'THIRD_AND_MORE', text: t('childOrder.thirdAndMore') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['ChildOrder']>(list),
  }
}

export const useDisabilityLevelEnum = () => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums['DisabilityLevel']> = [
    { id: 'FIRST_AND_SECOND', text: t('disabilityLevel.firstAndSecond') },
    { id: 'THIRD', text: t('disabilityLevel.third') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['DisabilityLevel']>(list),
  }
}

export const useMonthEnum = () => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums['Month']> = [
    { id: 'JANUARY', text: t('month.january') },
    { id: 'FEBRUARY', text: t('month.february') },
    { id: 'MARCH', text: t('month.march') },
    { id: 'APRIL', text: t('month.april') },
    { id: 'MAY', text: t('month.may') },
    { id: 'JUNE', text: t('month.june') },
    { id: 'JULY', text: t('month.july') },
    { id: 'AUGUST', text: t('month.august') },
    { id: 'SEPTEMBER', text: t('month.september') },
    { id: 'OCTOBER', text: t('month.october') },
    { id: 'NOVEMBER', text: t('month.november') },
    { id: 'DECEMBER', text: t('month.december') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['Month']>(list),
  }
}

export const useMonthShortcutEnum = () => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums['Month']> = [
    { id: 'JANUARY', text: t('month.januaryShortcut') },
    { id: 'FEBRUARY', text: t('month.februaryShortcut') },
    { id: 'MARCH', text: t('month.marchShortcut') },
    { id: 'APRIL', text: t('month.aprilShortcut') },
    { id: 'MAY', text: t('month.mayShortcut') },
    { id: 'JUNE', text: t('month.juneShortcut') },
    { id: 'JULY', text: t('month.julyShortcut') },
    { id: 'AUGUST', text: t('month.augustShortcut') },
    { id: 'SEPTEMBER', text: t('month.septemberShortcut') },
    { id: 'OCTOBER', text: t('month.octoberShortcut') },
    { id: 'NOVEMBER', text: t('month.novemberShortcut') },
    { id: 'DECEMBER', text: t('month.decemberShortcut') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['Month']>(list),
  }
}

export const useEmploymentTypeEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['EmploymentType']> = [
    { id: 'DPC', text: t('employmentType.DPC') },
    { id: 'DPP', text: t('employmentType.DPP') },
    { id: 'HPP', text: t('employmentType.HPP') },
    { id: 'SVF', text: t('employmentType.SVF') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['EmploymentType']>(list),
  }
}

export const useEmploymentTypeShortcutEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['EmploymentType']> = [
    { id: 'DPC', text: t('employmentType.DPCshortcut') },
    { id: 'DPP', text: t('employmentType.DPPshortcut') },
    { id: 'HPP', text: t('employmentType.HPPshortcut') },
    { id: 'SVF', text: t('employmentType.SVFshortcut') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['EmploymentType']>(list),
  }
}

export const useImportTypeEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['ImportType']> = [
    { id: 'EMPLOYEES', text: t('importType.employees') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['ImportType']>(list),
  }
}

export const useExportTypeEnum = () => {
  const list: IEnumList<NexusGenEnums['ExportType']> = [
    { id: 'BYZNYS', text: 'BYZNYS' },
    { id: 'SAP', text: 'SAP' },
    { id: 'NUGGET', text: 'NUGGET' },
    { id: 'HELIOS', text: 'HELIOS' },
    { id: 'LORGA', text: 'LORGA' },
    { id: 'EXPERT', text: 'EXPERT' },
    { id: 'OTHER', text: 'OTHER' },
  ]

  return {
    list,
  }
}

export const useUserStatusEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['UserStatusEnum']> = [
    { id: 'BLOCKED', text: t('userStatus.blocked') },
    { id: 'DELETED', text: t('userStatus.deleted') },
    { id: 'INVITED', text: t('userStatus.invited') },
    { id: 'REGISTERED', text: t('userStatus.registered') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['UserStatusEnum']>(list),
  }
}

export const useRebateStatusEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['RebateStatus']> = [
    { id: 'NEW', text: t('rebateStatus.new') },
    { id: 'APPLY', text: t('rebateStatus.apply') },
    { id: 'DENIED', text: t('rebateStatus.denied') },
    { id: 'CONFIRMED', text: t('rebateStatus.confirmed') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['RebateStatus']>(list),
  }
}

export const useRebateOverviewStatusEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['RebateOverviewStatus']> = [
    { id: 'NEW', text: t('rebateStatus.new') },
    { id: 'APPLY', text: t('rebateStatus.apply') },
    { id: 'DENIED', text: t('rebateStatus.denied') },
    { id: 'CONFIRMED', text: t('rebateStatus.confirmed') },
    { id: 'UNFINISHED', text: t('rebateStatus.unfinished') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['RebateOverviewStatus']>(list),
  }
}

export const useSettlementRequestStatuEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<
    NexusGenEnums['SettlementRequestStatus'] | 'UNFINISHED'
  > = [
    { id: 'NEW', text: t('rebateStatus.new') },
    { id: 'APPLY', text: t('rebateStatus.apply') },
    { id: 'DENIED', text: t('rebateStatus.denied') },
    { id: 'CONFIRMED', text: t('rebateStatus.confirmed') },
    { id: 'CANCELED', text: t('rebateStatus.canceled') },
    { id: 'UNFINISHED', text: t('rebateStatus.unfinished') },
  ]

  return {
    list,
    translate: createTranslate<
      NexusGenEnums['SettlementRequestStatus'] | 'UNFINISHED'
    >(list),
  }
}

export const useRebateTypeEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['RebateTypeEnum']> = [
    { id: 'ANNUAL', text: t('common.annualRebates2') },
    { id: 'MONTHLY', text: t('common.monthlyRebate') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['RebateTypeEnum']>(list),
  }
}

export const useSettlementStatusEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['SettlementRequestStatus']> = [
    { id: 'NEW', text: t('settlementStatus.new') },
    { id: 'CONFIRMED', text: t('settlementStatus.confirmed') },
    { id: 'DENIED', text: t('settlementStatus.denied') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['SettlementRequestStatus']>(list),
  }
}

export const useWorkEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['Work']> = [
    { id: 'OSVC', text: t('work.OSVC') },
    { id: 'EMPLOYER', text: t('work.EMPLOYER') },
    { id: 'UNENPLOYED', text: t('work.UNENPLOYED') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['Work']>(list),
  }
}

export const useLanguageEnum = () => {
  const { t } = useTranslation()
  const list: IEnumList<NexusGenEnums['Language']> = [
    { id: 'CS', text: 'Czech' },
    { id: 'EN', text: 'English' },
  ]
  const translatedList: IEnumList<NexusGenEnums['Language']> = [
    { id: 'CS', text: t('language.CS') },
    { id: 'EN', text: t('language.EN') },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['Language']>(translatedList),
  }
}

export const useActionEnum = () => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums['UserLogAction']> = [
    {
      id: 'DOCUMENT_CREATED',
      text: t('logStatus.document.created'),
    },
    {
      id: 'DOCUMENT_DELETED',
      text: t('logStatus.document.deleted'),
    },
    {
      id: 'DOCUMENT_UPDATED',
      text: t('logStatus.document.updated'),
    },
    {
      id: 'USER_UPDATED',
      text: t('logStatus.user.updated'),
    },
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums['UserLogAction']>(list),
  }
}

export const useRebateType = (isAnnual: boolean, isChildren = false) => {
  const { t } = useTranslation()

  const list: IEnumList<NexusGenEnums["RequestType"]> = [
    {
      id: 'NEW',
      text: isAnnual ? t('common.annualApply') : ''
    },
    {
      id: 'CLOSE_PERIOD',
      text: ''
    },
    {
      id: 'REMOVE',
      text: ''
    },
    {
      id: 'NEW_PERIOD',
      text: isAnnual ? t('common.annualApply') : isChildren ? t("common.monthlyChildrenRebateNewPeriodSR") : t("common.monthlyRebateNewPeriodSR")
    },
    {
      id: 'ALTER',
      text: t("common.monthlyRebateAlterSR")
    }
  ]

  return {
    list,
    translate: createTranslate<NexusGenEnums["RequestType"]>(list)
  }
}

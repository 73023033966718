import {
  ChildrenTable,
  ChildFiles,
  PrevRebate,
  getChildrenRebateType,
} from '../../../../../../shared/Children'
import { useRebateType, useWorkEnum } from '../../../../../../hooks/useEnumList'
import { Bold, Divider } from '../../../../../../components/CommonBox'
import CommentOfDenied from './CommentOfDenied'
import { Deti } from '../../../../../../components/icons/KubikIcons'
import { NexusGenFieldTypes } from 'kubik-server'
import { inject } from 'mobx-react'
import { Store } from '../Store'
import StatusSwitcher, {
  StatusView,
  StatusViewText,
} from './StatusSwitcher'
import { useTheme } from '@material-ui/core/styles'
import { TRebateType } from '../gql'
import { useTranslation } from 'react-i18next'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import FileLink from '../../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// const ChildContainer = styled(Grid)(({ theme }) => ({
//   borderTopColor: theme.palette.divider,
//   borderTopWidth: 1,
//   borderTopStyle: 'solid',
//   '&:last-child': {
//     borderBottomColor: theme.palette.divider,
//     borderBottomWidth: 1,
//     borderBottomStyle: 'solid',
//   },
// }))

const ChildrenView: React.FC<{
  data: NexusGenFieldTypes['ChildrenRebate']
  hideComment?: boolean
  dense?: boolean
  rebateType: TRebateType
  hideConfirmedChildren?: boolean
}> = (props) => {
  const { t } = useTranslation()
  const { translate: translateWork } = useWorkEnum()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  const { data, hideComment } = props

  const type = getChildrenRebateType(data)

  if (type === 'CLOSE_PERIOD') {
    return (
      <>
        {data.children &&
          data.children
            .filter((ch) => !!ch.closePeriodAt)
            .map((ch) => (
              <Grid key={ch.id} container>
                <Bold component="span" mr={1}>
                  {t('monthlyChildrenRebate.closePeriodLabel0_ACCOUNTANT')}
                </Bold>
                <Typography>{`${ch.firstname} ${ch.lastname}`}</Typography>
                <Bold mx={1} component="span">
                  {t('monthlyChildrenRebate.closePeriodLabel1')}
                </Bold>
                <Typography>
                  {ch.closePeriodAt &&
                    moment(ch.closePeriodAt).format('DD.MM.YYYY')}
                </Typography>
              </Grid>
            ))}

        {!hideComment && (
          <Grid item xs={12}>
            <CommentOfDenied rebateName="childrenRebate" />
          </Grid>
        )}
      </>
    )
  }

  if (type === 'REMOVE') {
    return (
      <>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Bold>{t('rebateChanges.removeRebate')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>

        {!hideComment && (
          <Grid item xs={12}>
            <CommentOfDenied rebateName="childrenRebate" />
          </Grid>
        )}
      </>
    )
  }

  let children = (data.children || [])

  if (
    props.hideConfirmedChildren &&
    (props.rebateType === 'ANNUAL' || props.rebateType === 'ANNUAL_CHANGE') &&
    (data.type === 'NEW_PERIOD' || data.type === 'ALTER')
  ) {
    children = children.filter(child => child.status === 'APPLY')
  }

  return (
    <>
      <Box mx={compactScreen ? 0 : props.dense ? -3 : -6} mb={6}>
        <ChildrenTable
          children={children}
          dense
          muteStatusColor
          fullInfo
        />
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={compactScreen ? 12 : 7}>
          <Bold>{t('monthlyChildrenRebate.otherParentNourishes')}</Bold>
        </Grid>
        <Grid item xs={compactScreen ? 12 : 5}>
          <Typography>
            {data.otherParentNourishes ? t('common.yes') : t('common.no')}
          </Typography>
        </Grid>
        {data.otherParentNourishes && (
          <>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('monthlyChildrenRebate.otherParentApplying')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>
                {data.otherParentApplying ? t('common.yes') : t('common.no')}
              </Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.firstname')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{data.otherParentFirstname}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.lastname')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{data.otherParentLastname}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.nationalIDNumber')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{data.otherParentNationalIDNumber}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.permanentAddress')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{data.otherParentAddress}</Typography>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 7}>
              <Bold>{t('common.work')}</Bold>
            </Grid>
            <Grid item xs={compactScreen ? 12 : 5}>
              <Typography>{translateWork(data.otherParentWork)}</Typography>
            </Grid>
            {data.otherParentWork === 'EMPLOYER' && (
              <>
                <Grid item xs={compactScreen ? 12 : 7}>
                  <Bold>{t('annualRebatePreviousEmployers.companyName')}</Bold>
                </Grid>
                <Grid item xs={compactScreen ? 12 : 5}>
                  <Typography>{data.otherParentWorkName}</Typography>
                </Grid>
                <Grid item xs={compactScreen ? 12 : 7}>
                  <Bold>{t('common.employerAddress')}</Bold>
                </Grid>
                <Grid item xs={compactScreen ? 12 : 5}>
                  <Typography>{data.otherParentWorkAddress}</Typography>
                </Grid>
              </>
            )}
          </>
        )}

        <Grid item xs={12}>
          <Bold mb={1} display="block">
            {t('common.uploadedConfirmations')}
          </Bold>
          <Box pl={compactScreen ? 0 : 4}>
            {data.otherParentConfirmationFiles &&
              data.otherParentConfirmationFiles.map((file) => (
                <FileLink
                  key={file.id}
                  file={file}
                  label={t('common.anotherParentsConfirmation')}
                />
              ))}
          </Box>
          {children && <ChildFiles children={children} />}
        </Grid>

        {!hideComment && (
          <Grid item xs={12}>
            <CommentOfDenied rebateName="childrenRebate" />
          </Grid>
        )}
      </Grid>
    </>
  )
}

// const ChildForm: React.FC<{
//   store?: Store
//   data: any
// }> = inject('store')((props) => {
//   const { t } = useTranslation()
//   return (
//     <Observer>
//       {() => {
//         const { data } = props
//         const store = props.store!
//         const childData = store.getChildData(data.id)

//         const status = (childData && childData.status) || 'APPLY'
//         const commentOfDenied = (childData && childData.commentOfDenied) || ''

//         const createStatusHandler = (status: 'CONFIRMED' | 'DENIED') => () => {
//           if (store.status === 'APPLY') {
//             store.updateChildData(data.id, { status })
//           }
//         }
//         const onCommentChange = (commentOfDenied: string) => {
//           store.updateChildData(data.id, { commentOfDenied })
//         }

//         return (
//           <ChildContainer container item>
//             <Grid
//               item
//               xs={12}
//               container
//               justify="space-between"
//               alignItems="center"
//             >
//               <Typography>
//                 <Typography color="textSecondary" component="span">
//                   {`${t('common.child')} `}
//                 </Typography>
//                 {` ${data.firstname} ${data.lastname}`}
//               </Typography>

//               <StatusSwitcherView
//                 status={status}
//                 {...(store.status === 'APPLY' && {
//                   createHandler: createStatusHandler,
//                 })}
//                 containerBoxProps={{ mb: 0 }}
//               ></StatusSwitcherView>
//             </Grid>
//             {status === 'DENIED' && (
//               <Grid item xs={12}>
//                 <CommentOfDeniedView
//                   status={status}
//                   settlementRequestStatus={store.status}
//                   commentOfDenied={commentOfDenied}
//                   onChange={onCommentChange}
//                   onBlur={() => {}}
//                 />
//               </Grid>
//             )}
//           </ChildContainer>
//         )
//       }}
//     </Observer>
//   )
// })

// const ChildFormReadonly: React.FC<{
//   data: any
// }> = (props) => {
//   const { t } = useTranslation()
//   const { data } = props
//   const status = (data && data.status) || 'APPLY'
//   const commentOfDenied = (data && data.commentOfDenied) || ''
//   return (
//     <ChildContainer container item>
//       <Grid item xs={12} container justify="space-between" alignItems="center">
//         <Typography>
//           <Typography color="textSecondary" component="span">
//             {`${t('common.child')} `}
//           </Typography>
//           {` ${data.firstname} ${data.lastname}`}
//         </Typography>

//         <StatusView status={status} containerBoxProps={{ mb: 0 }}></StatusView>
//       </Grid>
//       {status === 'DENIED' && (
//         <Grid item xs={12}>
//           <CommentOfDeniedReadonly commentOfDenied={commentOfDenied} />
//         </Grid>
//       )}
//     </ChildContainer>
//   )
// }

// const OtherParentForm: React.FC<{
//   store?: Store
// }> = inject('store')((props) => {
//   const { t } = useTranslation()
//   return (
//     <Observer>
//       {() => {
//         const store = props.store!
//         const rebateData = store.getOtherParentData()

//         const status = (rebateData && rebateData.otherParentStatus) || 'APPLY'
//         const commentOfDenied =
//           (rebateData && rebateData.otherParentCommentOfDenied) || ''

//         const createStatusHandler = (
//           otherParentStatus: 'CONFIRMED' | 'DENIED',
//         ) => () => {
//           if (store.status === 'APPLY') {
//             store.updateOtherParentData({ otherParentStatus })
//           }
//         }
//         const onCommentChange = (otherParentCommentOfDenied: string) => {
//           store.updateOtherParentData({ otherParentCommentOfDenied })
//         }

//         return (
//           <ChildContainer container item>
//             <Grid
//               item
//               xs={12}
//               container
//               justify="space-between"
//               alignItems="center"
//             >
//               <Typography>
//                 <Typography color="textSecondary" component="span">
//                   {t('common.anotherParentsConfirmation')}
//                 </Typography>
//               </Typography>

//               <StatusSwitcherView
//                 status={status}
//                 {...(store.status === 'APPLY' && {
//                   createHandler: createStatusHandler,
//                 })}
//                 containerBoxProps={{ mb: 0 }}
//               ></StatusSwitcherView>
//             </Grid>
//             {status === 'DENIED' && (
//               <Grid item xs={12}>
//                 <CommentOfDeniedView
//                   status={status}
//                   settlementRequestStatus={store.status}
//                   commentOfDenied={commentOfDenied}
//                   onChange={onCommentChange}
//                   onBlur={() => {}}
//                 />
//               </Grid>
//             )}
//           </ChildContainer>
//         )
//       }}
//     </Observer>
//   )
// })
// const OtherParentFormReadonly: React.FC<{
//   store?: Store
// }> = inject('store')((props) => {
//   const { t } = useTranslation()
//   return (
//     <Observer>
//       {() => {
//         const store = props.store!
//         const rebateData = store.getOtherParentData()

//         const status = (rebateData && rebateData.otherParentStatus) || 'APPLY'
//         const commentOfDenied =
//           (rebateData && rebateData.otherParentCommentOfDenied) || ''

//         const createStatusHandler = (
//           otherParentStatus: 'CONFIRMED' | 'DENIED',
//         ) => () => {
//           if (store.status === 'APPLY') {
//             store.updateOtherParentData({ otherParentStatus })
//           }
//         }
//         const onCommentChange = (otherParentCommentOfDenied: string) => {
//           store.updateOtherParentData({ otherParentCommentOfDenied })
//         }

//         return (
//           <ChildContainer container item>
//             <Grid
//               item
//               xs={12}
//               container
//               justify="space-between"
//               alignItems="center"
//             >
//               <Typography>
//                 <Typography color="textSecondary" component="span">
//                   {t('common.anotherParentsConfirmation')}
//                 </Typography>
//               </Typography>

//               <StatusView
//                 status={status}
//                 containerBoxProps={{ mb: 0 }}
//               ></StatusView>
//             </Grid>
//             {status === 'DENIED' && (
//               <Grid item xs={12}>
//                 <CommentOfDeniedReadonly commentOfDenied={commentOfDenied} />
//               </Grid>
//             )}
//           </ChildContainer>
//         )
//       }}
//     </Observer>
//   )
// })

interface IProps {
  data: NexusGenFieldTypes['ChildrenRebate']
  previous: NexusGenFieldTypes['ChildrenRebate'][]
  rebateType: TRebateType
  bottomDivider?: boolean
}

export const ChildrenRebatePrevious: React.FC<Omit<IProps, 'data'>> = (
  props,
) => {
  const { previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  const [first] = previous
  if (!first) return null

  const children = first.children || []

  const deniedChildren = (children || []).filter(
    (ch) => ch.status === 'DENIED' && ch.commentOfDenied,
  )
  const denied =
    first.otherParentStatus === 'DENIED' || deniedChildren.length > 0

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Deti fontSize="inherit" />
          </Box>

          <StatusView status={denied ? 'DENIED' : 'CONFIRMED'}>
            {t('rebate.childrenRebate')}
          </StatusView>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Deti fontSize="inherit" />
            </Box>
            <StatusView status={first.status} compactView />
          </Box>
          <Box>
            <StatusViewText status={first.status}>
              {t('rebate.childrenRebate')}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate key={data.id} data={data}>
            <ChildrenView
              data={data}
              rebateType={rebateType}
              hideComment
              dense
            />
          </PrevRebate>
        ))}
      </ArchiveChanges>
      {bottomDivider && <Divider my={6} />}
    </Box>
  )
}

const ChildrenRebate: React.FC<IProps & {
  store?: Store
}> = inject('store')((props) => {
  const { data, previous, rebateType, bottomDivider } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  // const children = data.children || []

  // const showForm = (status: 'APPLY' | 'CONFIRMED' | 'DENIED' | 'NEW') =>
  //   status === 'APPLY' ||
  //   (store.status !== 'APPLY' &&
  //     (status === 'CONFIRMED' || status === 'DENIED'))

  const isAnnual = rebateType === 'ANNUAL' || rebateType === 'ANNUAL_CHANGE' || rebateType === 'ANNUAL_POZP'

  const { translate } = useRebateType(isAnnual, true)

  const type = translate(data.type)
  const title = `${t('rebate.childrenRebate')} ${type ? `(${type})` : ''}`

  return (
    <Box width={780} my={2} position="relative" maxWidth="100%">
      {!compactScreen ? (
        <>
          <Box position="absolute" left={-60} top={0} fontSize="2.5rem">
            <Deti fontSize="inherit" />
          </Box>

          <StatusSwitcher rebateName="childrenRebate">
            {title}
          </StatusSwitcher>
        </>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box fontSize="2.5rem">
              <Deti fontSize="inherit" />
            </Box>
            <StatusSwitcher rebateName="childrenRebate" compactView>
              {title}
            </StatusSwitcher>
          </Box>
          <Box>
            <StatusViewText status={'APPLY'}>
              {title}
            </StatusViewText>
          </Box>
        </Box>
      )}

      <ChildrenView data={data} rebateType={rebateType} hideConfirmedChildren />

      <ArchiveChanges>
        {previous.map((data) => (
          <PrevRebate key={data.id} data={data}>
            <ChildrenView data={data} rebateType={rebateType} dense hideComment />
          </PrevRebate>
        ))}
      </ArchiveChanges>
      {bottomDivider && <Divider my={6} />}
    </Box>
  )
})

export default ChildrenRebate

import {
  IOwnAnnualStudentRebateData,
  IResetOwnAnnualStudentData,
  IUpdateOwnAnnualStudentRebateData,
  OWN_ANNUAL_STUDENT_REBATE_STUDENT,
  RESET_OWN_ANNUAL_STUDENT_REBATE,
  TUpdateOwnAnnualStudentRebateVariables,
  UPDATE_OWN_ANNUAL_STUDENT_REBATE_STUDENT,
} from './gql'
import {
  Center,
  Divider,
  Green
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import { FormProvider } from '../../../../../../hooks/useForm'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import { pickId, yearRange } from '../../../../../../utils'
import { useForm, useRouter, useUser } from '../../../../../../hooks'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import moment from 'moment'
import PrevRebate from '../../components/PrevRebate'
import React from 'react'
import ReApplyButton from '../../components/ReApplyButton'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Student from '../../../../../../components/icons/Student'
import StudentRebateOverview from '../../components/StudentRebateOverview'
import StudentWithPeriodsFormSection from '../../../../../../shared/StudentWithPeriodsFormSection'

const StudentRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualStudentRebateData,
    TUpdateOwnAnnualStudentRebateVariables
  >(UPDATE_OWN_ANNUAL_STUDENT_REBATE_STUDENT, { onError() {} })

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualStudentData>(RESET_OWN_ANNUAL_STUDENT_REBATE, {
    onError() {},
  })

  const { data, loading, error } = useQuery<IOwnAnnualStudentRebateData>(
    OWN_ANNUAL_STUDENT_REBATE_STUDENT,
    { fetchPolicy: 'cache-and-network', onError() {} },
  )
  const year = get(data, 'user.annualRebate.year')
  const [yearStart, yearEnd, prevYearStart, nextYearEnd] = yearRange(year)
  const formData = get(data, 'user.annualRebate.studentRebate') || {}

  const { bind, form } = useForm<
    TUpdateOwnAnnualStudentRebateVariables['data']
  >(
    formData,
    {
      prevYearPeriods: {
        type: 'multi_relation',
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
      },
      confirmationDoctoralStudies: {
        label: t('monthlyStudentRebate.doctoralStudies'),
        help: t('monthlyStudentRebate.doctoralStudiesHelp'),
      },
      studentConfirmationFiles: {
        type: 'multi_relation',
        label: t('monthlyStudentRebate.uploadLabel'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true,
        help: t('common.uploadHelp')
      },
    },
    {
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })
          if (!errors || (errors && errors.length > 0))
            history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevYear,
    },
  ] = getRebatesWithExtra(data && data.user.annualRebate, 'studentRebate')

  const validatePrevYearPeriods = () => {
    const doc: boolean = form.getValue('confirmationDoctoralStudies')
    const prevYearPeriods: { from: any; to: any }[] = form.getValue(
      'prevYearPeriods',
    )
    const ageLimit = moment(user.data.birthdate)
      .add(doc ? 28 : 26, 'year')
      .endOf('month')
    return prevYearPeriods.every(
      (pp) => moment(pp.to).isValid() && moment(pp.to).isSameOrBefore(ageLimit),
    )
  }

  const cleanRebate = async () => {
    if (formData) {
      const studentConfirmationFiles = formData.studentConfirmationFiles || []
      const prevYearPeriods = formData.prevYearPeriods || []
      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            prevYearPeriods: {
              delete: prevYearPeriods.map(pickId),
            },
            studentConfirmationFiles: {
              delete: studentConfirmationFiles.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (formData) {
      const type = form.getValue('type')
      const alreadyRemoved = type === 'REMOVE'

      if (isPrevDenied && !alreadyRemoved) {
        await updateRebate({
          variables: {
            data: {
              status: 'APPLY',
              type: 'REMOVE',
            },
          },
        })
      } else {
        await cleanRebate()
      }
      history.push(`/${user.data.id}/annual-rebates/rootMonthly`)
    }
  }

  const handleApplyAgain = async () => {
    await cleanRebate()
    form.setField('type', 'NEW_PERIOD')
  }
  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }
  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  const prevYearRebate = prevRebates.find((r) =>
    Boolean(
      r.settlementRequest && r.settlementRequest.type === 'MONTHLY_PREV_YEAR',
    ),
  )
  let prevYearLastTo =
    prevYearRebate &&
    prevYearRebate.prevYearPeriods &&
    prevYearRebate.prevYearPeriods.length > 0 &&
    prevYearRebate.prevYearPeriods[prevYearRebate.prevYearPeriods.length - 1].to
  if (prevYearLastTo && moment(prevYearLastTo).isValid()) {
    prevYearLastTo = moment(prevYearLastTo).add(1, 'day')
  }

  const prevYearIntervals = prevRebates
  .filter(rebate => rebate.status === "CONFIRMED")
  .map(rebate => {
    if (!rebate.prevYearPeriods) {
      return []
    }

    return rebate.prevYearPeriods.map(period => {
      return {
        from: moment(period.from),
        to: moment(period.to)
      }
    })
  })
  .flat()

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError} />

      {data && data.user && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey="monthlyStudentRebate.sideHint">
                <strong>Slevu na studenta</strong> můžete uplatňovat <strong>měsíčně</strong> nebo <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování &gt; krok Měsíční slevy dodatečné uplatnění). Daňová sleva je ve výši <strong>335 Kč měsíčně</strong> neboli <strong>4 020 Kč ročně</strong>.
              </Trans>
            }
            upperHint={`${t('common.annualRebates')} ${year}`}
            heading={t('monthlyStudentRebate.heading')}
            subHeading={
              <Trans i18nKey="monthlyStudentRebate.subHeading">
                Chcete-li uplatňovat Slevu na studenta, tak je nutné
                <Green>vyplnit platnost potvrzení</Green> a
                <Green>nahrát potvrzení o studiu</Green>
              </Trans>
            }
            icon={<Student fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog onSubmit={onCancelRebate} />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')
                const status = form.getValue('status')
                const doc: boolean = form.getValue(
                  'confirmationDoctoralStudies',
                )
                const ageLimit = moment(user.data.birthdate)
                  .add(doc ? 28 : 26, 'year')
                  .endOf('month')
                  .toDate()

                const doctoralIsValid = validatePrevYearPeriods()

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelStudent')}
                    </CancelRebateSection>
                  )
                }

                if (
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD'
                ) {
                  return (
                    <FormProvider form={form}>
                      <StudentWithPeriodsFormSection
                        bind={bind}
                        doctoralIsValid={doctoralIsValid}
                        excludeIntervals={prevYearIntervals}
                        dateLimiters={{
                          fromMinDates: [
                            {
                              date: prevYearStart
                            },
                            // {
                            //   date: type === "ALTER" ? null : prevYearLastTo,
                            // }
                          ],
                          toMinDates: [
                            {
                              date: yearStart,
                              message: t('error.minDateAnnualYearStart'),
                            },
                            // {
                            //   date: type === "ALTER" ? null : prevYearLastTo
                            // }
                          ],
                          fromMaxDates: [
                            {
                              date: yearEnd,
                              message: t('error.maxDateAnnualYearEnd'),
                            },
                            {
                              date: ageLimit,
                              message: t(
                                doc
                                  ? 'error.minDateAgeDocLimit'
                                  : 'error.minDateAgeLimit',
                              ),
                            },
                          ],
                          toMaxDates: [
                            {
                              date: nextYearEnd,
                            },
                            {
                              date: ageLimit,
                              message: t(
                                doc
                                  ? 'error.minDateAgeDocLimit'
                                  : 'error.minDateAgeLimit',
                              ),
                            },
                          ],
                        }}
                      />
                    </FormProvider>
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      {/* {isPrevYear || isPrevRemoved ? ( */}
                      <ChangeRebateChoice
                        variant="APPLY_AGAIN"
                        onClick={handleApplyAgain}
                      />
                       {/* ) : null} */}
                      <ChangeRebateChoice
                        variant="ALTER"
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD'

                return (
                  <Box maxWidth="100%" width={680} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <StudentRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')
                return (
                  <RebateActions
                    backTo={`/${user.data.id}/annual-rebates/rootMonthly`}
                    // checkValidity={() => {
                    //   if (validatePrevYearPeriods() && form.isValid()) {
                    //     return true
                    //   } else {
                    //     form.setTouchedAll()
                    //     return false
                    //   }
                    // }}
                    onSubmit={() => {
                      return validatePrevYearPeriods() && form.submit()
                    }}
                    isDirty={form.state.isDirty}
                    submitToBack={Boolean(isPrevYear && type === 'NEW')}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default StudentRebate

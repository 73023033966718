import Box from '@material-ui/core/Box'
import React from 'react'
import {TSharedLimits} from "../pages/Root";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {useTranslation} from "react-i18next";

interface IProps {
  limits: TSharedLimits
}

const SharedRebateLimitInfo: React.FC<IProps> = (props) => {
  const { limits } = props
  let total = 0
  let usedRebatesCnt = 0
  for(let rebateName in limits.currentRebates) {
    const amount = limits.currentRebates[rebateName]
    total += amount
    if(amount > 0) {
      usedRebatesCnt++
    }
  }
  const { t } = useTranslation()
  return (
    <>
      {usedRebatesCnt > 0 && <Table>
        <TableHead></TableHead>
        <TableBody>
          {Object.keys(limits.currentRebates).map((rebateName) => {
            const amount = limits.currentRebates[rebateName]
            return (
              <TableRow>
                <TableCell>
                  {t('rebate.' + rebateName)}
                </TableCell>
                <TableCell align={"right"}>{amount.toLocaleString('cs-CZ')} Kč</TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell>
              {t('rebate.sharedLimitInfo')} {limits.totalMax.toLocaleString('cs-CZ')} {t('common.CZK')}.
            </TableCell>
            <TableCell align={"right"}><strong>{total.toLocaleString('cs-CZ')} Kč</strong></TableCell>
          </TableRow>
        </TableBody>
      </Table>
      }
    </>
  )
}

export default SharedRebateLimitInfo

import {
  updateMonthlyRebateWizardStep,
  updateMonthlyRebateWizardStepVariables,
} from '../../../../../../generated/updateMonthlyRebateWizardStep'
import {
  Invalida,
  Poplatnik,
  Student,
  Deti,
  ZTPP,
  Exekuce
} from '../../../../../../components/icons/KubikIcons'
import {
  OWN_MONTHLY_REBATE,
  IOwnMonthlyRebateData,
  ISubmitMonthlyRebateData,
  SUBMIT_MONTHLY_REBATE,
  TSubmitMonthlyRebateVariables,
} from './gql'
import {
  UpperHint,
  Divider,
  Bold,
  Green,
} from '../../../../../../components/CommonBox'
import { getPrevRebates } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import {
  useChildrenRebateStats,
  PrevRebate as ChildrenPrevRebate,
} from '../../../../../../shared/Children'
import { QuestionnaireReadonlyView } from '../../../../../../shared/QuestionnaireMonthly'
import FormControls, {
  BackButton,
} from '../../../../../../components/form/FormControls'
import { UPDATE_MONTHLY_WIZARD_STEP } from '../../gql/updateMonthlyWizardStepMutation'
import { MonthlyWizardStep } from '../../../../../../generated/globalTypes'
import { useForm, useRouter, useUser } from '../../../../../../hooks'
import { useGlobalStateAtom } from '../../../../../../hooks/useGlobalState'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import BasicRebateOverview from '../../../AnnualRebates/components/BasicRebateOverview'
import Box from '@material-ui/core/Box'
import Conditions from './conditions'
import Fade from '../../../../../../components/Fade'
import Expand from '../../../../../../components/Expand'
import DisabilityRebateOverview from '../../../AnnualRebates/components/DisabilityRebateOverview'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import Checkbox from '../../../../../../components/form/Checkbox'
import ChildrenRebateOverview from '../../../AnnualRebates/components/ChildrenRebateOverview'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import MonthlyStepper from '../../components/Stepper'
import PrevRebate from '../../../AnnualRebates/components/PrevRebate'
import React, { useRef } from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import StudentRebateOverview from '../../../AnnualRebates/components/StudentRebateOverview'
import SummaryRebate from '../../../AnnualRebates/components/SummaryRebate'
import Typography from '@material-ui/core/Typography'
import ZtppRebateOverview from '../../../AnnualRebates/components/ZtppRebateOverview'
import TaxResidenceFilesOverview from '../../../AnnualRebates/components/TaxResidenceFilesOverview'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {FINISH_MONTHLY_REBATE_WIZARD, IFinishMonthlyRebateWizardData} from "../FinishWizard/gql";
import Loader from '../../../../../../components/Loader'
import { UserDetails } from "../../../../../../shared/Summary/UserDetails";
import MonthlyRebateSupport from "../../../Support/components/MonthlyRebateSupport";

interface IStylesProps {
  lgScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  sideHint: {
    display: (props) => (props.lgScreen ? undefined : 'none'),
  },
  mainBox: {
    marginRight: (props) => (props.lgScreen ? undefined : 'auto'),
    marginLeft: (props) => (props.lgScreen ? undefined : 'auto'),
  },
}))

const Root: React.FC = () => {
  const [,setFinishDialog] = useGlobalStateAtom("monthlyFinishWizardDialog", false)

  const { t } = useTranslation()
  const { user, refetch: refetchUser } = useUser()
  const { history } = useRouter()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const breakpoint = theme.breakpoints.up('lg')
  const lgScreen = useMediaQuery(breakpoint)
  const classes = useStyles({ lgScreen, isNotSmallScreen })

  const [finishMonthlyRebate, { loading, error }] = useMutation<
      IFinishMonthlyRebateWizardData
      >(FINISH_MONTHLY_REBATE_WIZARD, {
    onCompleted() {
      refetchUser().then(() =>
          history.push(`/${user.data.id}`, { from: 'monthlyRebatesFinish' }),
      )
    },
    onError() {},
  })

  const isReincarnation = user.isReincarnation()
  const isSupport = user.isSupportReincarnation()

  const [
    submitMonthlyRebate,
    { loading: submitLoading, submitError: submitError },
  ] = useMutation<ISubmitMonthlyRebateData, TSubmitMonthlyRebateVariables>(
    SUBMIT_MONTHLY_REBATE,
  )
  const onConfirm = () => {
    submitMonthlyRebate().then(() => {
      setConditions(false)
      finishMonthlyRebate()
    })
  }

  const { bind, form } = useForm<TSubmitMonthlyRebateVariables>(
    useRef({}).current,
    {
      agreementAt: {
        label: '-',
        rule: 'required|date',
      },
    },
    {
      async onSubmit(data, form) {
        try {
          await submitMonthlyRebate({
            variables: data,
          })

          await refetchUser()

          setFinishDialog({
            dontApply: noApplyingRebates
          })

          history.push(`/${user.data.id}/rebates`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [updateMonthlyWizardStep] = useMutation<
    updateMonthlyRebateWizardStep,
    updateMonthlyRebateWizardStepVariables
  >(UPDATE_MONTHLY_WIZARD_STEP)

  const { data, loading: queryLoading, queryError } = useQuery<
    IOwnMonthlyRebateData
  >(OWN_MONTHLY_REBATE, {
    fetchPolicy: 'cache-and-network',
    onCompleted(response) {
      const wizardStep = get(response, 'user.monthlyRebate.wizardStep')
      if (wizardStep !== MonthlyWizardStep.SUMMARY) {
        updateMonthlyWizardStep({
          variables: { wizardStep: MonthlyWizardStep.SUMMARY },
        })
      }
    },
  })

  const monthlyRebate = get(data, 'user.monthlyRebate')
  const taxQuestionnaire = monthlyRebate && monthlyRebate.taxQuestionnaire
  const year = get(data, 'user.monthlyRebate.year')

  let doesntWantToCreate = false
  let cannotCreate = false
  let reason: any = undefined
  if (taxQuestionnaire) {
    const { createByActualEmployer, madeByAnotherEmployer } = taxQuestionnaire

    doesntWantToCreate = createByActualEmployer === false
    cannotCreate =
      createByActualEmployer === true && madeByAnotherEmployer === true
  }

  let hasSomeRebate = false
  const getRebate = (rebateName: string) => {
    const rebate = get(data, `user.monthlyRebate.${rebateName}`)
    if (
      rebate &&
      rebate.status === 'APPLY' &&
      !hasSomeRebate &&
      !doesntWantToCreate &&
      !cannotCreate
    ) {
      hasSomeRebate = true
    }
    return rebate || {}
  }

  const basicRebateData = getRebate('basicRebate')
  const studentRebateData = getRebate('studentRebate')
  const childrenRebateData = get(data, 'user.monthlyRebate.childrenRebate')
  const prevChildrenRebates = get(data, 'user.monthlyRebate.childrenRebates')
  const disabilityRebateData = getRebate('disabilityRebate')
  const ztppRebateData = getRebate('ztppRebate')

  const childrenStats = useChildrenRebateStats(
    get(data, 'user.monthlyRebate.childrenRebates'),
    year,
  )
  const childrenApply = childrenStats.status === 'APPLY'

  if (childrenApply) {
    hasSomeRebate = true
  }

  const noApplyingRebates = doesntWantToCreate || cannotCreate;

  const [conditions, setConditions] = React.useState<boolean>(false)

  const navigateBack = () => {
    if (noApplyingRebates) {
      return `/${user.data.id}/monthly-rebates/questionnaire`
    }

    return `/${user.data.id}/monthly-rebates/root`
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error || submitError || queryError} />

      {data && (
        <Fade>
          <>
            <Box display="flex" justifyContent="center">
              <Box
                maxWidth="100%"
                width={lgScreen ? 1080 : null}
                display="flex"
                flexDirection="column"
                alignItems="center"
                className={classes.mainBox}
              >
                <UpperHint>
                  {t('common.form')} {t('common.monthlyRebates')} {year}
                </UpperHint>
                <MonthlyStepper activeStep={noApplyingRebates ? 3 : 4} noApplyingRebates={noApplyingRebates} />
                {!!monthlyRebate && <MonthlyRebateSupport monthlyRebate={monthlyRebate} />}
                <Typography variant="h1" align="center">
                  {t('rebateSummary.heading', { context: "2023" })}
                </Typography>
                {!queryLoading && monthlyRebate && taxQuestionnaire ? (
                  <Box maxWidth={880} width='100%'>
                    <Box mt={2}>
                      <Typography variant="h2" align="center">
                        {hasSomeRebate || noApplyingRebates
                          ? t('rebateSummary.subHeading')
                          : childrenStats.allChildrenAreNew ? t('taxRebates.noApplyingRebates', {
                            context: user.data.gender,
                          }) : t('taxRebates.noAdditionalApplyingRebates')}
                      </Typography>

                      <Divider maxWidth={180} my={4} />

                      <Typography align="center">
                        {noApplyingRebates ? (
                          <>
                            {doesntWantToCreate && (
                              <Trans
                                i18nKey="rebateSummary.monthly_notApplyingV1"
                                tOptions={{
                                  context: user.data.gender,
                                  company: user.data.customer.name,
                                }}
                                components={[<strong />]}
                              />
                              )}
                            {cannotCreate && !doesntWantToCreate && (
                              <Trans
                                i18nKey="rebateSummary.monthly_notApplyingV2"
                                tOptions={{
                                  context: user.data.gender,
                                  company: user.data.customer.name,
                                }}
                                components={[<strong />]}
                              />
                            )}
                          </>
                        ) : <Trans i18nKey='rebateSummary.monthly_applying' components={[<Bold />]} />}
                      </Typography>
                    </Box>

                    <Divider maxWidth={180} my={4} />

                    <UserDetails user={user} />

                    <Divider my={4} />

                    {taxQuestionnaire && taxQuestionnaire.status === 'APPLY' && (
                      <>
                        <Expand
                          title={t('common.questionnaire')}
                          titleVariant='h2'
                          expand={noApplyingRebates}
                        >
                          <SummaryRebate
                            icon={<React.Fragment />}
                            label={t('common.questionnaire')}
                            editLinkTo={`/${user.data.id}/monthly-rebates/questionnaire`}
                          >
                              <>
                                <QuestionnaireReadonlyView
                                  data={taxQuestionnaire}
                                  year={monthlyRebate.year}
                                  dateOfEmployment={user.data.dateOfEmployment}
                                  customerName={user.data.customer.name}
                                  employeeGender={user.data.gender}
                                />
                                {taxQuestionnaire.history && (
                                  <ArchiveChanges boxProps={{ mt: 1 }}>
                                    {taxQuestionnaire.history.map(
                                      (questionnaireSnap) => (
                                        <PrevRebate
                                          key={questionnaireSnap.id}
                                          status={questionnaireSnap.status}
                                          settlementRequest={
                                            questionnaireSnap.settlementRequest
                                          }
                                          commentOfDenied={
                                            questionnaireSnap.commentOfDenied
                                          }
                                        >
                                          <QuestionnaireReadonlyView
                                            data={questionnaireSnap}
                                            year={monthlyRebate.year}
                                            dateOfEmployment={
                                              user.data.dateOfEmployment
                                            }
                                            customerName={user.data.customer.name}
                                            employeeGender={user.data.gender}
                                          />
                                        </PrevRebate>
                                      ),
                                    )}
                                  </ArchiveChanges>
                                )}
                              </>
                          </SummaryRebate>
                        </Expand>
                        <Divider my={4} />
                      </>
                    )}

                    {user.hasNewResidenceFiles() && (
                      <>
                        <Expand title={t('common.czechTaxResidence')} titleVariant='h2' expand>
                          <SummaryRebate
                            icon={<React.Fragment />}
                            label={t('common.czechTaxResidence')}
                            editLinkTo={`/${user.data.id}/monthly-rebates/taxResidence`}
                          >
                            <TaxResidenceFilesOverview />
                          </SummaryRebate>
                        </Expand>
                        <Divider my={4} />
                      </>
                    )}

                    {hasSomeRebate && (
                      <>
                        <Expand title={t('common.taxRebates')} titleVariant='h2' expand>
                          {basicRebateData.status === 'APPLY' && (
                            <SummaryRebate
                              icon={<Poplatnik />}
                              label={t('rebate.basicRebate')}
                              editLinkTo={`/${user.data.id}/monthly-rebates/basic-rebate`}
                            >
                              <BasicRebateOverview data={basicRebateData} />
                              <ArchiveChanges>
                                {getPrevRebates(monthlyRebate, 'basicRebate').map(
                                  (rebate) => (
                                    <PrevRebate
                                      key={rebate.id}
                                      settlementRequest={rebate.settlementRequest}
                                      status={rebate.status}
                                      commentOfDenied={rebate.commentOfDenied}
                                    >
                                      <BasicRebateOverview data={rebate} isHistory/>
                                    </PrevRebate>
                                  ),
                                )}
                              </ArchiveChanges>
                            </SummaryRebate>
                          )}
                          {/*{studentRebateData.status === 'APPLY' && (*/}
                          {/*  <SummaryRebate*/}
                          {/*    icon={<Student />}*/}
                          {/*    label={t('rebate.studentRebate')}*/}
                          {/*    editLinkTo={`/${user.data.id}/monthly-rebates/student-rebate`}*/}
                          {/*  >*/}
                          {/*    <StudentRebateOverview data={studentRebateData} />*/}
                          {/*    <ArchiveChanges>*/}
                          {/*      {getPrevRebates(*/}
                          {/*        monthlyRebate,*/}
                          {/*        'studentRebate',*/}
                          {/*      ).map((rebate) => (*/}
                          {/*        <PrevRebate*/}
                          {/*          key={rebate.id}*/}
                          {/*          settlementRequest={rebate.settlementRequest}*/}
                          {/*          status={rebate.status}*/}
                          {/*          commentOfDenied={rebate.commentOfDenied}*/}
                          {/*        >*/}
                          {/*          <StudentRebateOverview data={rebate} />*/}
                          {/*        </PrevRebate>*/}
                          {/*      ))}*/}
                          {/*    </ArchiveChanges>*/}
                          {/*  </SummaryRebate>*/}
                          {/*)}*/}
                          {childrenApply && (
                            <SummaryRebate
                              icon={<Deti />}
                              label={t('rebate.childrenRebate')}
                              editLinkTo={`/${user.data.id}/monthly-rebates/children-rebate`}
                            >
                              <ChildrenRebateOverview
                                data={{...childrenRebateData, monthlyRebate: data.user.monthlyRebate}}
                                prevChildrenRebates={prevChildrenRebates}
                                muteStatusColor
                              />
                              <ArchiveChanges>
                                {getPrevRebates(
                                  monthlyRebate,
                                  'childrenRebate',
                                ).map((rebate) => (
                                  <ChildrenPrevRebate
                                    key={rebate.id}
                                    data={rebate}
                                  >
                                    <ChildrenRebateOverview data={rebate} dense />
                                  </ChildrenPrevRebate>
                                ))}
                              </ArchiveChanges>
                            </SummaryRebate>
                          )}
                          {disabilityRebateData.status === 'APPLY' && (
                            <SummaryRebate
                              icon={<Invalida />}
                              label={t('rebate.disabilityRebate')}
                              editLinkTo={`/${user.data.id}/monthly-rebates/disability-rebate`}
                            >
                              <DisabilityRebateOverview
                                data={disabilityRebateData}
                              />
                              <ArchiveChanges>
                                {getPrevRebates(
                                  monthlyRebate,
                                  'disabilityRebate',
                                ).map((rebate) => (
                                  <PrevRebate
                                    key={rebate.id}
                                    settlementRequest={rebate.settlementRequest}
                                    status={rebate.status}
                                    commentOfDenied={rebate.commentOfDenied}
                                  >
                                    <DisabilityRebateOverview data={rebate} />
                                  </PrevRebate>
                                ))}
                              </ArchiveChanges>
                            </SummaryRebate>
                          )}
                          {ztppRebateData.status === 'APPLY' && (
                            <SummaryRebate
                              icon={<ZTPP />}
                              label={t('rebate.ztppRebate')}
                              editLinkTo={`/${user.data.id}/monthly-rebates/ztpp-rebate`}
                            >
                              <ZtppRebateOverview data={ztppRebateData} />
                              <ArchiveChanges>
                                {getPrevRebates(monthlyRebate, 'ztppRebate').map(
                                  (rebate) => (
                                    <PrevRebate
                                      key={rebate.id}
                                      settlementRequest={rebate.settlementRequest}
                                      status={rebate.status}
                                      commentOfDenied={rebate.commentOfDenied}
                                    >
                                      <ZtppRebateOverview data={rebate} isHistory />
                                    </PrevRebate>
                                  ),
                                )}
                              </ArchiveChanges>
                            </SummaryRebate>
                          )}
                      </Expand>
                      <Divider my={4} />
                      </>
                    )}
                  </Box>
                ) : (
                  <Box my={4}>
                    <Loader loading={true} />
                  </Box>
                )}

                {isReincarnation && !isSupport && (
                  <BackButton
                    backTo={`/${user.data.id}/monthly-rebates/root`}
                    formLoading={form.state.loading}
                  />
                )}

                {year && (!isReincarnation || isSupport) && (
                  <>
                    <Box maxWidth={880} width='100%'>
                      <Observer>
                        {() => {
                          const { error, ...rest } = bind('agreementAt')
                          return (
                            <Checkbox
                              {...rest}
                              timestamp
                              error={
                                error ? t('rebateSummary.agreementError') : ''
                              }
                              label={
                                <Bold>
                                  <Trans i18nKey={!noApplyingRebates ? "rebateSummary.agreement" : "rebateSummary.agreementNoRebate" }>
                                    Potvrzuji pravdivost a úplnost údajů
                                    uvedených v této žádosti o daňové slevy
                                    <br /> a souhlasím s
                                    <a
                                      href="/"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        setConditions(true)
                                      }}
                                    >
                                      podmínkami
                                    </a>
                                    .
                                  </Trans>
                                </Bold>
                              }
                            />
                          )
                        }}
                      </Observer>
                    </Box>

                    {(!noApplyingRebates) && (
                      <Box maxWidth={880} width='100%' mt={3} pl={4}>
                        <Bold>
                          <Trans i18nKey="rebateSummary.text1">
                            Žádost o daňové slevy a kontrolu mzdovou účetní
                            pošlete vybráním „<Green>Potvrdit</Green>“ níže.
                          </Trans>
                        </Bold>
                      </Box>
                    )}

                    <Divider my={4} />

                    <FormControls
                      backTo={navigateBack()}
                      disabled={form.state.loading}
                      onSubmit={() => {
                        if(isSupport && !window.confirm('Opravdu chcete odeslat formulář jménem zaměstnance?')) {
                          return
                        }
                        form.submit()
                      }}
                      submitContent={t('common.finish')}
                      formLoading={form.state.loading}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Conditions
              open={conditions}
              onClose={() => setConditions(false)}
            />
          </>
        </Fade>
      )}
    </InsideLayout>
  )
}

export default Root

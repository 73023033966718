
import React from 'react'
import {makeStyles} from '@material-ui/core/styles'

import useUser from "../../../../../hooks/useUser";
import Podpora from "../../../../../components/icons/Podpora";
import {Label} from "../../../../../components/form/InlineFormContainer";
import moment from "moment";
import {useMutation} from "react-apollo";
import {SET_MONTHLY_REBATE_SETTINGS, ISetMonthlyRebateSettings, ISetMonthlyRebateSettingsVariables} from "./gql";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "@material-ui/core";
import {Observer} from "mobx-react";

const useStyles = makeStyles(() => ({
  container: {
    padding: '1rem',
    borderRadius: '0.5rem',
    background: '#fafafa',
    margin: '1rem'
  },
  heading: {display: "flex", alignItems: "center", justifyContent: "center"}
}))

interface Props {
  monthlyRebate: {
    id: string,
    year: number
    allowSendAnythingOnce: boolean
  },
  refetch?: () => Promise<void>
}

const MonthlyRebateSupport: React.FC<Props> = ({ monthlyRebate, refetch }) => {
  const { user, refetch: refetchUser } = useUser()
  const classes = useStyles()

  const [
    updateRebate,
    { loading },
  ] = useMutation<ISetMonthlyRebateSettings, ISetMonthlyRebateSettingsVariables>(SET_MONTHLY_REBATE_SETTINGS, { onError() {} })

  if(!user.isSupportReincarnation()) {
    return null
  }

  const setAllowSendAnything = async (to: boolean) => {
    await updateRebate({
      variables: {
        id: monthlyRebate.id,
        allowSendAnythingOnce: to
      }
    })
    if(refetch) {
      await refetch()
    }
    await refetchUser()
  }

  return (
    <div className={classes.container}>
      <Observer>
        {() => (
          <>
            <div className={classes.heading}>
              {!loading && <Podpora color={"primary"}/>}
              {loading && <CircularProgress size={'1rem'}/>}
            </div>
            <div>
              {!monthlyRebate.allowSendAnythingOnce &&
                <>
                  <div>
                    <span>Výběr datumů tohoto formuláře je omezen rozhodným datem: {moment(user.data.minRebateDate).format('DD.MM.YYYY')}.</span>
                  </div>
                  <Link style={{cursor: 'pointer'}} onClick={() => setAllowSendAnything(true)}>změnit pro jedno odeslání</Link>
                </>
              }
              {!!monthlyRebate.allowSendAnythingOnce &&
                <>
                  <div>
                    <Label>Příští odeslání tohoto formuláře není omezeno rozhodným datem.</Label>
                  </div>
                  <Link style={{cursor: 'pointer'}} onClick={() => setAllowSendAnything(false)}>změnit zpět</Link>
                </>
              }
            </div>
          </>
        )}
      </Observer>
    </div>
  )
}

export default MonthlyRebateSupport

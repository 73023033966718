
import {createMuiTheme, makeStyles, Theme} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import MuiStepper from '@material-ui/core/Stepper'
import React, {useEffect, useRef, useState} from 'react'
import MuiStep from "@material-ui/core/Step";
import MuiStepLabel from "@material-ui/core/StepLabel";
import MuiStepButton from "@material-ui/core/StepButton";
import {StepButtonIcon} from "@material-ui/core/StepButton";
import Typography from "@material-ui/core/Typography";
import {ThemeProvider} from "@material-ui/styles";
import {Check} from "./icons/KubikIcons";
import classnames from "classnames";
import { NexusGenEnums } from 'kubik-server';
import useRouter from '../hooks/useRouter';

const overrideTheme = (theme: Theme, isVertical: boolean) => ({
    ...theme,
    overrides: {
        ...theme.overrides,
        MuiStepper: {
            ...(theme.overrides? theme.overrides.MuiStepper : {}),
            vertical: {
                margin: '0',
                width: '100vw'
            }
        },
        MuiStep: {
            ...(theme.overrides? theme.overrides.MuiStep : {}),
            root: {
                position: "relative",
                paddingLeft: "0 !important",
                paddingRight: "0 !important",
                marginBottom: "2rem",
                '& $.MuiStep-label': {
                    margin: "-20px",
                }
            },
            vertical: {
                position: "static",
                marginBottom: "0",
            }
        },
        MuiStepLabel: {
            ...(theme.overrides? theme.overrides.MuiStepLabel : {}),
            labelContainer: {
                ...(theme.overrides.MuiStepLabel? theme.overrides.MuiStepLabel.labelContainer : {}),
                position: isVertical? 'relative' : "absolute",
                top: isVertical? 0 : "100%",
                width: isVertical? '100%' : "150%",
                margin: isVertical? '0 3rem' : undefined,
                '& span': isVertical? {
                    margin: 0
                } : undefined
            },
            vertical: {
                flexDirection: 'row',
            }
        },
        MuiStepConnector: {
            ...(theme.overrides? theme.overrides.MuiStepConnector : {}),
            root: {
                ...(theme.overrides && theme.overrides.MuiStepConnector? theme.overrides.MuiStepConnector.root : {}),
                '& span': {
                    borderWidth: "3px",
                }
            },
            vertical: {
                padding: '0',
                marginLeft: '20px'
            }
        },
    }
})

//@ts-ignore
const useStyles = makeStyles<Theme>((theme) => {
    return {
        step: {
            "&[data-completed='true'] + div span": {
                borderColor: theme.palette.primary.main
            },
            "&[data-error='true'] + div span": {
                borderColor: theme.palette.error.main
            },
            "&[data-active='true'] + div span": {
                borderColor: theme.palette.primary.main
            }
        },
        label: {
            wordSpacing: "9999px"
        },
        container: {
            position: "relative",
            overflow: 'visible'
        },
        percentageLabel: {
            position: "absolute",
            display: "block",
            textAlign: "left",
            color: theme.palette.primary.main,
            fontWeight: "bold",
            right: "-4rem",
            top: "3.4rem",
            fontSize: "1.5rem",
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
        },
        percentageLabelVertical: {
            position: "absolute",
            display: "block",
            textAlign: "right",
            color: theme.palette.primary.main,
            fontWeight: "bold",
            right: "1rem",
            top: "45%",
            fontSize: "1.4rem",
            fontFamily: ['Montserrat', 'sans-serif'].join(',')
        },
        stepLabelRoot: {
            pointerEvents: "none"
        },
        stepLabelRootVertical: {
            pointerEvents: "none",
            position: 'relative',
            textAlign: 'left',
            top: 0,
            width: '100%',
            maxWidth: '100%'
        },
        stepIcon: {
            borderWidth: "3px",
            "& svg": {
                fontSize: "1.8rem",
                margin: "0.3rem"
            },
            "& svg g path": {
                fill: "#aaa"
            },
        },
        stepIconActiveWithState: {
            background: "#fff !important",
            "& svg g path": {
                fill: "currentColor !important"
            },
        },
        stepIconVertical: {
            "& svg": {
                fontSize: "1rem",
                margin: "0"
            },
            "& svg g path": {
                fill: "#aaa"
            },
        },
        stepLabelCompleted: {
            color: theme.palette.primary.main + " !important",
            fontWeight: "bolder !important"
        },
        stepLabelActive: {
            color: theme.palette.primary.main + " !important",
            fontWeight: "bolder !important"
        },
        stepLabelError: {
            color: theme.palette.error.main + " !important",
            fontWeight: "bolder !important"
        },
        stepIconError: {
            borderColor: theme.palette.error.main,
            background: theme.palette.error.main,
            "& svg g path": {
                fill: "#fff"
            }
        },
        stepIconCompleted: {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main,
            color: theme.palette.primary.main,
            "& svg": {
                fontSize: "1.5rem",
                margin: "0.4rem"
            },
            "& svg g path": {
                fill: "#fff"
            }
        },
        stepIconCompletedVertical: {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main,
            "& svg": {
                fontSize: "1rem",
                margin: "0"
            },
            "& svg g path": {
                fill: "#fff"
            }
        },
        stepIconActive: {
            borderColor: theme.palette.primary.main,
            background: "#fff",
            boxShadow: "0 0 12px -4px " + theme.palette.primary.main,
            "& svg g path": {
                fill: theme.palette.primary.main
            }
        },
        stepButton: {
            margin: 0,
            padding: 0,
            borderRadius: "100%",
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
            "&:hover": {
                boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
            }
        },
        stepButtonVertical: {
            borderRadius: 0,
        }
    }
})

export interface PercentageStep {
  label: string,
  icon: StepButtonIcon
  state?: 'CONFIRMED' | 'DENIED' | string | undefined | null
  link?: string
}

interface IProps {
    activeStep: number,
    steps: PercentageStep[]
    nonLinear: boolean
}

const PercentageStepper = React.forwardRef<HTMLElement, IProps>((props, ref) => {
    const timeout = useRef<NodeJS.Timeout | null>(null)
    
    const { activeStep, steps } = props
    const classes = useStyles()
    const { history } = useRouter()

    const shouldBeVertical = () => (window.screen.width / steps.length < 170)
    const [isVertical, setIsVertical] = useState(shouldBeVertical())
    // @ts-ignore
    const themeOverride = (theme) => createMuiTheme(overrideTheme(theme, isVertical))
    const percents = steps.length > 0? Math.min((Math.floor((activeStep) / steps.length * 100) ), 100) : '-';

    useEffect(() => {
        function handleResize() {
            if (timeout.current) {
                clearTimeout(timeout.current)
            }

            timeout.current = setTimeout(() => {
                console.log(shouldBeVertical())
                setIsVertical(shouldBeVertical())
            }, 300)
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (timeout.current) {
                clearTimeout(timeout.current)
            }
        }
    }, []);

    return (
        <Box className={classes.container}>
            <ThemeProvider theme={themeOverride}>
                <MuiStepper activeStep={activeStep} nonLinear={props.nonLinear} orientation={isVertical? 'vertical' : 'horizontal'} ref={ref}>
                    {steps.map((step: PercentageStep, index: number) => {
                        const isActive = activeStep === index && !step.state
                        const isActiveWithState = activeStep === index && step.state
                        const isError = step.state === 'DENIED' && !isActive
                        const isCompleted = (step.state === 'CONFIRMED' && !isActive) || (!step.state && activeStep > index)

                        return (
                            <MuiStep
                                active={isActive}
                                completed={isCompleted}
                                className={classes.step}
                                key={step.label}
                                data-completed={isCompleted}
                                data-error={isError}
                                data-active={isActive}
                            >
                                <MuiStepButton
                                    icon={step.icon}
                                    disabled={!step.link || !props.nonLinear}
                                    classes={{
                                        root: classnames(classes.stepButton, {
                                            [classes.stepButtonVertical]: isVertical
                                        })
                                    }}
                                    onClick={() => {
                                        if (step.link) {
                                            history.push(step.link)
                                        }
                                    }}
                                >
                                    <MuiStepLabel
                                        error={isError}
                                        icon={isCompleted ? <Check /> : step.icon}
                                        classes={{
                                            root: isVertical ? classes.stepLabelRootVertical : classes.stepLabelRoot,
                                            label: isVertical ? "" : classes.label,
                                            iconContainer: classnames(
                                                isVertical ? classes.stepIconVertical : classes.stepIcon,
                                                {
                                                    [classes.stepIconActive]: isActive,
                                                    [classes.stepIconActiveWithState]: isActiveWithState,
                                                    [isVertical ? classes.stepIconCompletedVertical : classes.stepIconCompleted]: isCompleted,
                                                    [classes.stepIconError]: isError
                                                }
                                            ),
                                            active: classes.stepLabelActive,
                                            completed: classes.stepLabelCompleted,
                                            error: classes.stepLabelError,
                                        }}
                                    >
                                        {step.label}
                                    </MuiStepLabel>
                                </MuiStepButton>
                            </MuiStep>
                        )
                    })}
                </MuiStepper>
            </ThemeProvider>
            <Typography className={isVertical? classes.percentageLabelVertical : classes.percentageLabel}>{percents} %</Typography>
        </Box>
    )
})

export default PercentageStepper
